import RestartAltIcon from "@mui/icons-material/RestartAlt";
import SearchIcon from "@mui/icons-material/Search";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Pagination from "@mui/material/Pagination";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useQuery } from "@tanstack/react-query";
import koLocale from "date-fns/locale/ko";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useRecoilState, useSetRecoilState } from "recoil";
import { getPaymentsDetail } from "../../../api/v2/Payments";
import {
  PaymentDetailData,
  PaymentsDetailParams
} from "../../../api/v2/Payments/types";
import AdminLayout from "../../../components/Layout/Admin/Layout";
import LoadingButton from "../../../components/LoadingButton";
import ModalTriggerButton from "../../../components/PopupTriggerButton";
import MuiTable from "../../../components/Table/MuiTable";
import Title from "../../../components/Title";
import useRenewal from "../../../hooks/useRenewal";
import { getDate } from "../../../libs/get-date";
import { getToday } from "../../../libs/get-today";
import { addCommas } from "../../../libs/thousands-commas";
import isModalOpenAtom from "../../../recoil/isModalOpen";
import PaymentsState, {
  PaymentsDetailState
} from "../../../recoil/payments/atom";
import DeletePurchaseModal from "./components/DeletePurchaseModal";
import PurchasePayments from "./components/PurchasePayments";
import PurchaseRegisterModal from "./components/PurchaseRegisterModal";
import { getMissingPurchases } from "./libs";
import { getMissingPurchasesParams, MissingPurchasesData } from "./libs/types";

interface PaymentColumnHeadCell {
  id: keyof MissingPurchasesData;
  label: string;
  isTotalData: boolean;
  isSort: boolean;
}

const columns: PaymentColumnHeadCell[] = [
  {
    id: "mallId",
    label: "가맹점 ID",
    isTotalData: true,
    isSort: false
  },
  {
    id: "mallName",
    label: "가맹점명",
    isTotalData: false,
    isSort: false
  },
  {
    id: "requestPurchaseStatus",
    label: "매입처리상태",
    isTotalData: false,
    isSort: false
  },
  {
    id: "businessName",
    label: "사업자 등록증 상호명",
    isTotalData: false,
    isSort: false
  },
  {
    id: "merchantGrade",
    label: "거래등급",
    isTotalData: false,
    isSort: false
  },
  {
    id: "createdAt",
    label: "거래 일자",
    isTotalData: true,
    isSort: true
  },
  // {
  //   id: "approvalNum",
  //   label: "승인 번호",
  //   isTotalData: false,
  //   isSort: false
  // },
  {
    id: "cardCompany",
    label: "카드사",
    isTotalData: false,
    isSort: false
  },
  {
    id: "status",
    label: "거래 상태",
    isTotalData: false,
    isSort: false
  },
  {
    id: "price",
    label: "거래 금액",
    isTotalData: true,
    isSort: false
  },
  {
    id: "transactionKey",
    label: "거래 KEY",
    isTotalData: false,
    isSort: false
  },
  {
    id: "paymentKey",
    label: "거래고유 KEY",
    isTotalData: false,
    isSort: false
  }
];

const PurchaseManagement = () => {
  const { isRenewalTokenLoading, mutateRenewalToken } = useRenewal();
  // ===============================================================================================
  // 리코일 스테이트
  // ===============================================================================================
  const setIsModalOpen = useSetRecoilState(isModalOpenAtom);

  const defaultFormValues = {
    startDate: getDate(
      new Date(new Date().setFullYear(new Date().getFullYear() - 1))
    ), // 1년 전
    endDate: getToday(),
    cardCompany: "기본",
    transactionKey: "",
    paymentKey: "",
    page: 0,
    pageSize: 10
  };

  const [params, setParams] =
    useState<getMissingPurchasesParams>(defaultFormValues);

  const [selected, setSelected] = useState<MissingPurchasesData[]>([]);
  // 오름차순, 내림차순 스테이트
  const [sortOrder, setSortOrder] = useState<"ASC" | "DESC">("ASC");

  // ===============================================================================================
  // 리액트 훅 폼
  // ===============================================================================================
  const { register, handleSubmit, control, reset } = useForm<any>({
    defaultValues: {
      ...defaultFormValues
    }
  });
  // 조회 초기화
  const handleReset = () => {
    reset(defaultFormValues);
  };
  // 리코일 스테이트 초기화
  // useEffect(() => {
  //   setPaymentsTotal(defaultFormValues);
  //   setPaymentsDetail({
  //     ...defaultFormValues,
  //     merchantGrade: "",
  //     page: 0,
  //     size: 10,
  //     sort: paymentsDetail.sort
  //   });
  // }, [setPaymentsDetail, setPaymentsTotal]);

  // ===================================================================================================================
  // 리액트 쿼리 - 매입 누락 거래 목록 조회
  // ===================================================================================================================
  const { data, isLoading } = useQuery(
    ["/purchase/missing", params],
    () => getMissingPurchases(params),
    {
      onSuccess: (response) => {
        if (isRenewalTokenLoading) return;
        mutateRenewalToken();
      },
      // select: (data) => ({
      //   ...data,
      //   content: data.content.map((paymentData: PaymentDetailData) => ({
      //     ...paymentData,
      //     price: `${addCommas(Number(paymentData.price))}`
      //   }))
      // }),
      onError: (error: any) => {
        setIsModalOpen({
          value: true,
          position: "top",
          alertSeverity: "error",
          message: error?.response?.data?.message
        });
      }
    }
  );

  // ===============================================================================================
  // 검색 조회
  // ===============================================================================================
  const handleForm = (data: getMissingPurchasesParams) => {
    const isInvalidData =
      data.startDate?.includes("NaN") || data.endDate?.includes("NaN");
    const isInvaildPeriod = data.startDate > data.endDate;
    if (isInvalidData || isInvaildPeriod) {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: "거래 일자를 확인해주세요."
      });
      return;
    }
    setParams((prevValue) => ({
      ...prevValue,
      ...data
    }));
  };

  // ===================================================================================================================
  // 페이징
  // ===================================================================================================================
  const totalPages = data?.pageable?.totalPages
    ? data?.pageable?.totalPages
    : 0;
  const handlePage = (event: React.ChangeEvent<unknown>, page: number) => {
    setParams((prevValue: any) => ({ ...prevValue, page: page - 1 }));
  };

  // ===================================================================================================================
  // 액션 버튼 핸들러
  // ===================================================================================================================
  const 등록전 = selected.every(
    (list) => list.requestPurchaseStatus === "등록전"
  );
  const 등록완료 = selected.every(
    (list) => list.requestPurchaseStatus === "등록완료"
  );

  const isMixedSelection = selected.some(
    (list) =>
      list.requestPurchaseStatus !== "등록전" &&
      list.requestPurchaseStatus !== "등록완료"
  );

  const isButtonDisabled =
    selected.length === 0 || isMixedSelection || (!등록전 && !등록완료);

  return (
    <>
      <Title title="매입 관리" />
      <AdminLayout>
        <Stack spacing={3}>
          <Paper
            sx={{
              p: 3,
              border: "1px solid #F2F3F5",
              borderRadius: 3
            }}
          >
            <form onSubmit={handleSubmit(handleForm)}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <LocalizationProvider
                      adapterLocale={koLocale}
                      dateAdapter={AdapterDateFns}
                    >
                      <Grid item xs={2}>
                        <Controller
                          name="startDate"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <DatePicker
                              label="거래 시작 날짜"
                              inputFormat="yyyy-MM-dd"
                              value={value}
                              onChange={(value) => onChange(getDate(value))}
                              renderInput={(param) => (
                                <TextField
                                  fullWidth
                                  size="small"
                                  required
                                  {...param}
                                />
                              )}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <Controller
                          name="endDate"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <DatePicker
                              label="거래 종료 날짜"
                              inputFormat="yyyy-MM-dd"
                              value={value}
                              onChange={(value) => onChange(getDate(value))}
                              renderInput={(param) => (
                                <TextField
                                  fullWidth
                                  size="small"
                                  required
                                  {...param}
                                />
                              )}
                            />
                          )}
                        />
                      </Grid>
                    </LocalizationProvider>

                    <Grid item xs={2}>
                      <TextField
                        id="mallId"
                        label="거래 KEY"
                        fullWidth
                        size="small"
                        {...register("transactionKey")}
                      />
                    </Grid>

                    <Grid item xs={2}>
                      <TextField
                        id="mallId"
                        label="거래고유 KEY"
                        fullWidth
                        size="small"
                        {...register("paymentKey")}
                      />
                    </Grid>

                    <Grid item xs={4}>
                      <Stack
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                        spacing={1}
                      >
                        <LoadingButton
                          icon={<SearchIcon />}
                          size="medium"
                          fullWidth={false}
                          loading={false}
                        >
                          조회
                        </LoadingButton>
                        <Button
                          variant="outlined"
                          endIcon={<RestartAltIcon />}
                          onClick={handleReset}
                        >
                          조건 초기화
                        </Button>
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Paper>

          {/* 매입 누락 거래 목록  */}
          <Box>
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"end"}
              mb={1}
            >
              <Typography sx={{ fontWeight: "bold" }}>
                매입 누락 거래 목록
              </Typography>
              <ModalTriggerButton
                modal={
                  등록전 ? (
                    <PurchaseRegisterModal selectedData={selected} />
                  ) : (
                    <DeletePurchaseModal selectedData={selected} />
                  )
                }
                disabled={isButtonDisabled}
              >
                {등록전 ? "매입 등록" : "매입 등록 철회"}
              </ModalTriggerButton>
            </Stack>

            <Paper
              sx={{
                border: "1px solid #F2F3F5",
                borderRadius: 3,
                overflow: "hidden"
              }}
            >
              {/* 정산 내역 상세 조회 테이블 */}
              <MuiTable
                rows={data?.content}
                columns={columns}
                id="id"
                selectedValues={selected}
                setRowSelection={setSelected}
                totalData={false}
                redlineKey="status"
                redlineValue={["취소", "부분취소"]}
                // sortingTarget={"datePeriod"}
                // sortDirection={settlementData.sort}
              />

              <Box py={2}>
                <Pagination
                  count={totalPages}
                  variant="outlined"
                  shape="rounded"
                  page={params.page + 1}
                  onChange={handlePage}
                  sx={{ display: "flex", justifyContent: "center" }}
                />
              </Box>
            </Paper>
          </Box>

          {/* 매입 등록 거래 목록 */}
          <PurchasePayments />
        </Stack>
      </AdminLayout>
    </>
  );
};

export default PurchaseManagement;
