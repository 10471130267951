import RestartAltIcon from "@mui/icons-material/RestartAlt";
import SearchIcon from "@mui/icons-material/Search";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useQuery } from "@tanstack/react-query";
import { subYears } from "date-fns";
import koLocale from "date-fns/locale/ko";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { getMerchantNames } from "../../../api/v2/Payments";
import AdminLayout from "../../../components/Layout/Admin/Layout";
import LoadingButton from "../../../components/LoadingButton";
import Title from "../../../components/Title";
import useRenewal from "../../../hooks/useRenewal";
import { getDate } from "../../../libs/get-date";
import { getToday } from "../../../libs/get-today";
import { addCommas } from "../../../libs/thousands-commas";
import isModalOpenAtom from "../../../recoil/isModalOpen";
import PayoutDetailModal from "./components/PayoutDetailModal";
import { getPayoutHistories } from "./libs";
import { PayoutHistoryData } from "./libs/types";
import Pagination from "@mui/material/Pagination";

interface FormInputs {
  startDate: string;
  endDate: string;
  mallId: string;
  mallName: string;
  payoutType: string;
  page: number;
}

interface SettlementColumnHeadCell {
  id: keyof PayoutHistoryData;
  label: string;
  isTotalData: boolean;
  isSort: boolean;
}

const columns: SettlementColumnHeadCell[] = [
  {
    id: "payoutYearMonth",
    label: "연월",
    isTotalData: true,
    isSort: true
  },
  {
    id: "mallId",
    label: "가맹점 ID",
    isTotalData: false,
    isSort: false
  },
  {
    id: "mallName",
    label: "가맹점명",
    isTotalData: false,
    isSort: false
  },
  {
    id: "businessName",
    label: "사업자 등록증 상호명",
    isTotalData: true,
    isSort: false
  },
  {
    id: "payoutType",
    label: "정산 지급 종류",
    isTotalData: false,
    isSort: false
  },
  {
    id: "lastPayoutDate",
    label: "마지막 정산지급일",
    isTotalData: false,
    isSort: false
  },
  {
    id: "currentMonthPayoutAmount",
    label: "당월 정산 지급액",
    isTotalData: false,
    isSort: false
  },
  {
    id: "currentMonthUnpaidAmount",
    label: "당월 정산 미지급금",
    isTotalData: false,
    isSort: false
  }
];

const getOneYearAgo = () => {
  const today = new Date();
  return getDate(subYears(today, 1));
};

const defaultFormValues = {
  startDate: getOneYearAgo(),
  endDate: getToday(),
  mallId: "",
  mallName: "",
  payoutType: "",
  page: 0,
  pageSize: 10,
  sort: ""
};

const SettlementPayout = () => {
  const { isRenewalTokenLoading, mutateRenewalToken } = useRenewal();
  // ===================================================================================================================
  // 리코일 스테이트
  // ===================================================================================================================
  const setIsModalOpen = useSetRecoilState(isModalOpenAtom);

  // ===================================================================================================================
  // 스테이트
  // ===================================================================================================================
  // 파라미터 스테이트
  const [params, setParams] = useState<FormInputs>(defaultFormValues);

  // 선택된 row 스테이트
  const [selectedData, setSelectedData] = useState({
    isModalOpen: false,
    id: 0
  });

  // 가맹점명 입력값 스테이트
  const [searchTerm, setSearchTerm] = useState("");
  const { search } = useLocation();

  // ===================================================================================================================
  // 리액트 훅 폼
  // ===================================================================================================================
  // form 기본 값
  const { register, handleSubmit, control, reset, setValue } =
    useForm<FormInputs>({
      defaultValues: {
        ...defaultFormValues
      }
    });

  // 폼 초기화
  const handleReset = () => {
    reset(defaultFormValues);
  };

  // ===================================================================================================================
  // 리액트 쿼리
  // ===================================================================================================================
  // 현재 운영중인 가맹점 목록 조회
  const { data: getMerchantsNameData, isLoading: getMerchantIsLoading } =
    useQuery(["/merchants/names"], () => getMerchantNames(""));

  const handleInputChange = (event: any) => {
    setSearchTerm(event.target.value);
  };

  // 정산 지급 내역 조회
  const { isLoading, data } = useQuery(
    ["/payout-histories", params],
    () => getPayoutHistories(params),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onSuccess: () => {
        if (isRenewalTokenLoading) return;
        mutateRenewalToken();
      },
      select: (data) => ({
        ...data,
        content: data.content.map((data: PayoutHistoryData) => ({
          ...data,
          payoutType: data.payoutType === "1" ? "지급완료" : "일부지급"
        }))
      }),
      onError: (error: any) => {
        setIsModalOpen({
          value: true,
          position: "top",
          alertSeverity: "error",
          message: error?.response?.data?.message
        });
      }
    }
  );

  useEffect(() => {
    if (search !== "") {
      const receivedDate = search.split("=")[1];
      setParams((prev) => ({
        ...prev,
        startDate: receivedDate,
        endDate: receivedDate
      }));
      setValue("startDate", receivedDate);
      setValue("endDate", receivedDate);
    }
  }, [search, setValue]);

  // ===================================================================================================================
  // 조회 & 정산 추가
  // ===================================================================================================================
  // 정산 지급 내역 조회
  const handleForm = (data: FormInputs) => {
    const isInvalidData =
      data.startDate?.includes("NaN") || data.endDate?.includes("NaN");
    const isInvaildPeriod = data.startDate > data.endDate;
    if (isInvalidData || isInvaildPeriod) {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: "검색 날짜를 확인해주세요."
      });
      return;
    }
    if (isLoading) return;
    setParams((prevValue) => ({
      ...prevValue,
      ...data,
      payoutType: data.payoutType === "전체" ? "" : data.payoutType
    }));
  };

  const selectedRow = (row: any) => {
    setSelectedData({ ...row, isModalOpen: true });
  };

  const closeModal = () => {
    setSelectedData({
      isModalOpen: false,
      id: 0
    });
  };

  // ===================================================================================================================
  // 페이지네이션
  // ===================================================================================================================
  // 정산 지급 내역 총 페이지 수
  const totalPages = data?.pageable?.totalPages
    ? data?.pageable?.totalPages
    : 0;

  // 정산 지급 내역 페이지 변경 함수
  const handlePage = (event: React.ChangeEvent<unknown>, page: number) => {
    setParams((prevValue) => ({ ...prevValue, page: page - 1 }));
  };

  return (
    <>
      <Title title="정산 지급 내역" />
      <AdminLayout>
        <Stack spacing={3}>
          <Paper
            sx={{
              p: 3,
              border: "1px solid #F2F3F5",
              borderRadius: 3,
              overflow: "hidden"
            }}
          >
            <form onSubmit={handleSubmit(handleForm)}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={1.5}>
                  <LocalizationProvider
                    adapterLocale={koLocale}
                    dateAdapter={AdapterDateFns}
                  >
                    <Controller
                      name="startDate"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <DatePicker
                          label="시작 날짜"
                          inputFormat="yyyy-MM-dd"
                          value={value}
                          onChange={(value) => onChange(getDate(value))}
                          renderInput={(param) => (
                            <TextField
                              fullWidth
                              size="small"
                              required
                              {...param}
                            />
                          )}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} md={1.5}>
                  <LocalizationProvider
                    adapterLocale={koLocale}
                    dateAdapter={AdapterDateFns}
                  >
                    <Controller
                      name="endDate"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <DatePicker
                          label="종료 날짜"
                          inputFormat="yyyy-MM-dd"
                          value={value}
                          onChange={(value) => onChange(getDate(value))}
                          renderInput={(param) => (
                            <TextField
                              fullWidth
                              size="small"
                              required
                              {...param}
                            />
                          )}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} md={1.5}>
                  <TextField
                    id="input-mallId"
                    label="가맹점 ID"
                    fullWidth
                    size="small"
                    {...register("mallId")}
                  />
                </Grid>
                <Grid item xs={12} md={1.5}>
                  <Autocomplete
                    id="mallName"
                    options={getMerchantsNameData?.content ?? []}
                    loading={getMerchantIsLoading}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        id="mallName"
                        label="가맹점명"
                        fullWidth
                        size="small"
                        {...register("mallName")}
                        onChange={handleInputChange}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={1.5}>
                  <Controller
                    name="payoutType"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        label="정산 지급 종류"
                        required
                        fullWidth
                        size="small"
                        select
                        value={value || "전체"}
                        onChange={onChange}
                      >
                        <MenuItem value={"전체"}>전체</MenuItem>
                        <MenuItem value={"1"}>지급완료</MenuItem>
                        <MenuItem value={"2"}>일부지급</MenuItem>
                      </TextField>
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={4.5}>
                  <Stack
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    spacing={1}
                  >
                    <LoadingButton
                      icon={<SearchIcon />}
                      size="medium"
                      fullWidth={false}
                      loading={isLoading}
                    >
                      조회
                    </LoadingButton>
                    <Button
                      variant="outlined"
                      endIcon={<RestartAltIcon />}
                      onClick={handleReset}
                    >
                      조건 초기화
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </form>
          </Paper>

          <Box>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems={"end"}
              mb={1}
            >
              <Typography sx={{ fontWeight: "bold" }}>
                정산 지급 내역 조회
              </Typography>
            </Stack>

            <Paper
              sx={{
                border: "1px solid #F2F3F5",
                borderRadius: 3,
                overflow: "hidden"
              }}
            >
              {/* 정산 지급 내역 조회 테이블 */}
              <TableContainer sx={{ minHeight: 550 }}>
                <Table>
                  <TableHead>
                    <TableRow sx={{ backgroundColor: "#FBFBFB" }}>
                      {columns.map((column) => (
                        <TableCell
                          variant="head"
                          align="center"
                          key={column.id}
                          sx={{ borderTop: "none" }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data?.content.length === 0 ? (
                      <>
                        <TableRow>
                          <TableCell
                            sx={{ height: 550 }}
                            colSpan={columns.length}
                          >
                            <Typography align="center">
                              조회된 데이터가 없습니다.
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </>
                    ) : (
                      <>
                        {!data &&
                          isLoading &&
                          [...Array(7)].map((_, index) => (
                            <TableRow key={index}>
                              {columns.map((column) => (
                                <TableCell key={column.id}>
                                  <Skeleton
                                    variant="rounded"
                                    animation="wave"
                                  />
                                </TableCell>
                              ))}
                            </TableRow>
                          ))}
                        {data?.content &&
                          !isLoading &&
                          data.content.map((row) => (
                            <TableRow
                              key={row.mallId}
                              onClick={() => selectedRow(row)}
                              sx={{ cursor: "pointer" }}
                              hover
                            >
                              <TableCell align="center">
                                {row.payoutYearMonth}
                              </TableCell>
                              <TableCell align="center">{row.mallId}</TableCell>
                              <TableCell align="center">
                                {row.mallName}
                              </TableCell>
                              <TableCell align="center">
                                {row.businessName}
                                
                              </TableCell>
                              <TableCell align="center">
                                {row.payoutType}
                              </TableCell>
                              <TableCell align="center">
                                {row.lastPayoutDate}
                              </TableCell>
                              <TableCell align="center">
                                {addCommas(row.currentMonthPayoutAmount)}
                              </TableCell>
                              <TableCell align="center">
                                {addCommas(row.currentMonthUnpaidAmount)}
                              </TableCell>
                            </TableRow>
                          ))}
                      </>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>

              <Box mt={2}>
                <Pagination
                  count={totalPages}
                  variant="outlined"
                  shape="rounded"
                  page={params.page + 1}
                  onChange={handlePage}
                  sx={{ display: "flex", justifyContent: "center", my: 2 }}
                />
              </Box>

              <PayoutDetailModal
                isOpen={selectedData.isModalOpen}
                closeModal={closeModal}
                selectedId={selectedData.id}
              />
            </Paper>
          </Box>
        </Stack>
      </AdminLayout>
    </>
  );
};

export default SettlementPayout;
