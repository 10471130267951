import RestartAltIcon from "@mui/icons-material/RestartAlt";
import SaveIcon from "@mui/icons-material/Save";
import SearchIcon from "@mui/icons-material/Search";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Pagination from "@mui/material/Pagination";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useQuery } from "@tanstack/react-query";
import koLocale from "date-fns/locale/ko";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useSetRecoilState } from "recoil";
import AdminLayout from "../../../../components/Layout/Admin/Layout";
import LoadingButton from "../../../../components/LoadingButton";
import MuiTable from "../../../../components/Table/MuiTable";
import Title from "../../../../components/Title";
import useRenewal from "../../../../hooks/useRenewal";
import excelDownload from "../../../../libs/excel-download";
import { getDate } from "../../../../libs/get-date";
import { getToday } from "../../../../libs/get-today";
import { addCommas } from "../../../../libs/thousands-commas";
import isModalOpenAtom from "../../../../recoil/isModalOpen";
import { getChargeList } from "./libs";
import { ChargeSearchParmas, ChargeSearchResponse } from "./libs/types";
import { endOfWeek, format, startOfWeek, subWeeks } from "date-fns";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Skeleton from "@mui/material/Skeleton";
import SettlementStatementModal from "./components/SettlementStatementModal";

interface ChargeSearchForm {
  type: string;
  startDate: string;
  endDate: string;
  mallId: string;
  mallName: string;
}

interface ChargeColumnHeadCell {
  id: keyof ChargeSearchResponse;
  label: string;
}

const columns: ChargeColumnHeadCell[] = [
  {
    id: "mallId",
    label: "가맹점 ID"
  },
  {
    id: "payoutDate",
    label: "지급 일자"
  },
  {
    id: "documentNumber",
    label: "문서번호"
  },
  {
    id: "mallName",
    label: "가맹점명"
  },
  {
    id: "businessName",
    label: "사업자 등록증 상호명"
  },
  {
    id: "chargeDate",
    label: "청구 일자"
  },
  {
    id: "chargeReasonType",
    label: "청구 종류"
  },
  {
    id: "chargeReason",
    label: "청구 이유"
  },
  {
    id: "chargeAmount",
    label: "청구 금액"
  }
];

const getSettlementPeriod = () => ({
  startDate: format(
    startOfWeek(subWeeks(new Date(), 1), { weekStartsOn: 0 }),
    "yyyy-MM-dd"
  ),
  endDate: format(
    endOfWeek(subWeeks(new Date(), 1), { weekStartsOn: 0 }),
    "yyyy-MM-dd"
  )
});

const ChargesV2 = () => {
  const { isRenewalTokenLoading, mutateRenewalToken } = useRenewal();
  // ===================================================================================================================
  // 리코일 스테이트
  // ===================================================================================================================
  const setIsModalOpen = useSetRecoilState(isModalOpenAtom);

  const [modalState, setModalState] = useState({
    isOpen: false,
    settlementId: 0
  });

  // ===================================================================================================================
  // 스테이트
  // ===================================================================================================================
  const [params, setParams] = useState<ChargeSearchParmas>({
    type: "",
    ...getSettlementPeriod(),
    isExcel: false,
    sort: "ASC",
    mallId: "",
    page: 0,
    pageSize: 10,
    isPayout: "",
    mallName: ""
  });

  // ===================================================================================================================
  // 리액트 훅 폼
  // ===================================================================================================================
  const { control, register, handleSubmit, reset, getValues, watch } =
    useForm<ChargeSearchForm>({
      defaultValues: {
        type: "",
        ...getSettlementPeriod(),
        mallId: "",
        mallName: ""
        //isPayout: ""
      }
    });

  // ===================================================================================================================
  // 리액트 쿼리
  // ===================================================================================================================
  // 청구금 목록 조회
  const { data, isLoading } = useQuery(
    ["/v3/charges", params],
    () => getChargeList(params),
    {
      select: (data) => {
        return {
          ...data,
          content: data.content.map((charge) => ({
            ...charge,
            chargeAmount: `${addCommas(charge.chargeAmount)}`,
            chargeDate: charge.chargeDate.split("T")[0]
          }))
        };
      },
      onSuccess: () => {
        if (isRenewalTokenLoading) return;
        mutateRenewalToken();
      }
    }
  );

  // ===================================================================================================================
  // 모달 열기, 닫기
  // ===================================================================================================================
  const openModal = (settlementId: number) => {
    setModalState({ isOpen: true, settlementId });
  };

  const closeModal = () => {
    setModalState({ isOpen: false, settlementId: 0 });
  };

  // ===================================================================================================================
  // 조회 버튼 클릭시
  // ===================================================================================================================
  const searchClicked = (data: ChargeSearchForm) => {
    // 이미 검색 중이면 함수 종료
    if (isLoading) return;

    // 날짜 검증 1 : 날짜 값이 있는지 확인
    const isInvalidData =
      data.startDate.includes("NaN") || data.endDate.includes("NaN");

    // 날짜 검증 2 : 날짜 앞뒤가 맞는지 확인
    const isInvaildPeriod = data.startDate > data.endDate;

    // 날짜 검증 실패시 에러 모달
    if (isInvalidData || isInvaildPeriod) {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: "검색 날짜를 확인해주세요."
      });
      return;
    }

    // 쿼리 데이터 설정
    setParams((prev: ChargeSearchParmas) => ({
      ...prev,
      ...data,
      //isPayout: data.isPayout === "전체" ? "" : data.isPayout,
      type: data.type === "전체" ? "" : data.type
    }));
  };

  // ===================================================================================================================
  // 페이징 처리
  // ===================================================================================================================
  // 총 페이지 수 산출
  const totalPages = data?.pageable?.totalPages
    ? data?.pageable?.totalPages
    : 0;
  // 페이지 변경시 조회 처리
  const changePage = (event: React.ChangeEvent<unknown>, page: number) => {
    setParams((prev) => ({ ...prev, page: page - 1 }));
  };

  // ===================================================================================================================
  // 엑셀 다운로드 버튼 클릭시
  // ===================================================================================================================
  const excelDownloadClicked = async () => {
    // 이미 다운로드 실행 중이면 종료
    // if (isExcelFetching) return;

    // 데이터가 없으면 종료
    if (!data || data?.content.length === 0) {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: "데이터가 없습니다."
      });
      return;
    }

    // 폼 데이터 가져오기
    const values = getValues();

    // 날짜 검증 1 : 날짜 값이 있는지 확인
    const isInvalidData =
      values.startDate?.includes("NaN") || values.endDate?.includes("NaN");

    // 날짜 검증 2 : 날짜 앞 뒤가 맞는지 확인
    const isInvaildPeriod = values.startDate > values.endDate;

    // 날짜 검증 실패시 에러 모달 팝업
    if (isInvalidData || isInvaildPeriod) {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: "검색 날짜를 확인해주세요."
      });
      return;
    }

    // 엑셀 다운로드 쿼리 실행
    // refetchExcel();
    try {
      const response = await getChargeList({ ...params, isExcel: true });
      const list = response.content.map((data) => ({
        "가맹점 ID": data.mallId,
        가맹점명: data.mallName,
        "사업자 등록증 상호명": data.businessName,
        "청구 일자": data.chargeDate,
        "지급 일자": data.payoutDate,
        "청구 종류": data.chargeReasonType,
        "청구 금액": data.chargeAmount,
        "청구 이유": data.chargeReason
      }));
      excelDownload({
        list,
        sheetName: `청구금 목록 조회`,
        fileName: `청구금목록조회.xlsx`
      });

      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "success",
        message: "다운로드를 시작합니다."
      });
    } catch (error: any) {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: error?.response?.data?.message || "엑셀 다운로드 실패"
      });
    }
  };

  // ===================================================================================================================
  // 조건 초기화 버튼 클릭시
  // ===================================================================================================================
  const initSearchForm = () => {
    reset({
      type: "",
      ...getSettlementPeriod(),
      mallId: "",
      mallName: ""
      //isPayout: ""
    });
  };

  // ===================================================================================================================
  // row 클릭 핸들러
  // ===================================================================================================================
  const handleRowClick = (row: any) => {
    openModal(row.settlementId);
  };

  return (
    <>
      <Title title="청구금" />
      <AdminLayout>
        <Stack spacing={3}>
          <Paper
            sx={{
              p: 3,
              border: "1px solid #F2F3F5",
              borderRadius: 3,
              overflow: "hidden"
            }}
          >
            <form onSubmit={handleSubmit(searchClicked)}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid container spacing={3}>
                    <LocalizationProvider
                      adapterLocale={koLocale}
                      dateAdapter={AdapterDateFns}
                    >
                      <Grid item xs={1.5}>
                        <Controller
                          name="startDate"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <DatePicker
                              label="시작 날짜"
                              inputFormat="yyyy-MM-dd"
                              value={value}
                              onChange={(value) => onChange(getDate(value))}
                              renderInput={(param) => (
                                <TextField
                                  fullWidth
                                  size="small"
                                  required
                                  {...param}
                                />
                              )}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={1.5}>
                        <Controller
                          name="endDate"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <DatePicker
                              label="종료 날짜"
                              inputFormat="yyyy-MM-dd"
                              value={value}
                              onChange={(value) => onChange(getDate(value))}
                              renderInput={(param) => (
                                <TextField
                                  fullWidth
                                  size="small"
                                  required
                                  {...param}
                                />
                              )}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={1.5}>
                        <Controller
                          name="type"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <TextField
                              id="input-status"
                              label="청구 종류"
                              fullWidth
                              size="small"
                              select
                              value={value || "전체"}
                              onChange={onChange}
                              required
                            >
                              <MenuItem value={"전체"}>전체</MenuItem>
                              <MenuItem value={"연회비"}>연회비</MenuItem>
                              <MenuItem value={"관리비"}>관리비</MenuItem>
                              <MenuItem value={"기타"}>기타</MenuItem>
                            </TextField>
                          )}
                        />
                      </Grid>
                      <Grid item xs={1.5}>
                        <TextField
                          id="input-mallId"
                          label="가맹점 ID"
                          fullWidth
                          size="small"
                          {...register("mallId")}
                        />
                      </Grid>
                      <Grid item xs={1.5}>
                        <TextField
                          id="input-mallName"
                          label="가맹점명"
                          fullWidth
                          size="small"
                          {...register("mallName")}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <Stack
                          direction="row"
                          alignItems="center"
                          spacing={2}
                          justifyContent={"flex-end"}
                        >
                          <LoadingButton
                            icon={<SearchIcon />}
                            size="medium"
                            fullWidth={false}
                            loading={isLoading}
                          >
                            조회
                          </LoadingButton>
                          <Button
                            variant="outlined"
                            endIcon={<RestartAltIcon />}
                            onClick={initSearchForm}
                          >
                            초기화
                          </Button>
                        </Stack>
                      </Grid>
                    </LocalizationProvider>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Paper>

          <Box>
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"end"}
              mb={1}
            >
              <Typography sx={{ fontWeight: "bold" }}>
                청구금 목록 조회
              </Typography>

              {/* loading 수정하기 🚨 */}
              <LoadingButton
                icon={<SaveIcon />}
                size="medium"
                fullWidth={false}
                color="secondary"
                variant="outlined"
                loading={false}
                handleClick={excelDownloadClicked}
              >
                EXCEL 다운로드
              </LoadingButton>
            </Stack>
            <Paper
              sx={{
                border: "1px solid #F2F3F5",
                borderRadius: 3,
                overflow: "hidden"
              }}
            >
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      {columns.map((column: any) => (
                        <TableCell
                          variant="head"
                          key={column.id}
                          align="center"
                          sx={{ background: "#FBFBFB" }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(!data || data.content.length === 0) && !isLoading ? (
                      <TableRow>
                        <TableCell sx={{ height: 550 }} colSpan={columns.length}>
                          <Typography align="center">
                            조회된 데이터가 없습니다.
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ) : (
                      <>
                        {data &&
                          data.content.length > 0 &&
                          data.content.map((row) => (
                            <TableRow
                              key={row.id}
                              hover={true}
                              onClick={() => handleRowClick(row)}
                              sx={{ cursor: "pointer" }}
                            >
                              <TableCell align="center">{row.mallId}</TableCell>
                              <TableCell align="center">
                                {row.payoutDate}
                              </TableCell>
                              <TableCell align="center">
                                {row.documentNumber}
                              </TableCell>
                              <TableCell align="center">
                                {row.mallName}
                              </TableCell>
                              <TableCell align="center">
                                {row.businessName}
                              </TableCell>
                              <TableCell align="center">
                                {row.chargeDate}
                              </TableCell>

                              <TableCell align="center">
                                {row.chargeReasonType}
                              </TableCell>
                              <TableCell align="center">
                                {row.chargeReason}
                              </TableCell>
                              <TableCell align="center">
                                {row.chargeAmount}
                              </TableCell>
                            </TableRow>
                          ))}
                      </>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>

              <SettlementStatementModal
                settlementId={modalState.settlementId}
                isOpen={modalState.isOpen}
                onClose={closeModal}
              />

              <Box my={2}>
                <Pagination
                  count={totalPages}
                  variant="outlined"
                  shape="rounded"
                  page={params.page + 1}
                  onChange={changePage}
                  sx={{ display: "flex", justifyContent: "center" }}
                />
              </Box>
            </Paper>
          </Box>
        </Stack>
      </AdminLayout>
    </>
  );
};

export default ChargesV2;
