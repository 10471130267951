import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { useMutation } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSetRecoilState } from "recoil";
import { queryClient } from "../..";
import { putMerchantMemo } from "../../api/Merchants";
import isModalOpen from "../../recoil/isModalOpen";

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  memo: string;
  merchantId: string;
}

interface Form {
  memo: string;
}

const MerchantMemoModal = ({ isOpen, closeModal, memo, merchantId }: Props) => {
  // ===================================================================================================================
  // 리코일 스테이트
  // ===================================================================================================================
  const setIsModalOpen = useSetRecoilState(isModalOpen);

  // ===================================================================================================================
  // 스테이트
  // ===================================================================================================================
  const [isEditing, setIsEditing] = useState(!memo);

  // ===================================================================================================================
  // 리액트 쿼리
  // ===================================================================================================================
  const { mutate } = useMutation(putMerchantMemo, {
    onSuccess: () => {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "success",
        message: memo ? "수정 완료 되었습니다." : "등록 완료 되었습니다."
      });
      queryClient.invalidateQueries(["/merchants"]);
    },
    onError: (error: any) => {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: error?.response?.data?.message
      });
    }
  });

  // ===================================================================================================================
  // 리액트 훅 폼
  // ===================================================================================================================
  const { handleSubmit, register, reset, watch } = useForm<Form>({
    values: {
      memo: memo || ""
    },
    mode: "onChange"
  });

  // memo가 변경될 때 폼 리셋
  useEffect(() => {
    reset({ memo: memo || "" });
    setIsEditing(!memo); // memo가 없으면 등록 모드로 설정
  }, [memo, reset]);

  const onClose = () => {
    setIsEditing(false);
    closeModal();
  };

  // ===================================================================================================================
  // 수정 완료
  // ===================================================================================================================
  const onSubmit = (formData: Form) => {
    const params = {
      memo: formData.memo,
      merchantId: merchantId
    };
    mutate(params);
    setIsEditing(false);
    onClose();
  };

  return (
    <>
      <Dialog open={isOpen} fullWidth maxWidth="xs">
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
          }}
        >
          가맹점 메모 내용
          <Stack direction={"row"} gap={2}>
            {!isEditing && memo && (
              <Button variant="outlined" onClick={() => setIsEditing(true)}>
                메모 수정하기
              </Button>
            )}
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Stack>
        </DialogTitle>

        <DialogContent>
          <DialogContentText gutterBottom>
            <form onSubmit={handleSubmit(onSubmit)}>
              <TextField
                fullWidth
                size="small"
                {...register("memo", {
                  required: "메모를 입력해 주세요."
                })}
                disabled={isEditing ? false : true}
                multiline
                minRows={3}
              />
            </form>
          </DialogContentText>
        </DialogContent>

        {isEditing && (
          <DialogActions>
            <Button
              variant="contained"
              sx={{ color: "white" }}
              fullWidth
              onClick={handleSubmit(onSubmit)}
              disabled={!watch("memo")}
            >
              {memo ? "수정 완료" : "등록 완료"}
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </>
  );
};
export default MerchantMemoModal;
