import RestartAltIcon from "@mui/icons-material/RestartAlt";
import SearchIcon from "@mui/icons-material/Search";
import Autocomplete from "@mui/material/Autocomplete";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useQuery } from "@tanstack/react-query";
import koLocale from "date-fns/locale/ko";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useRecoilState, useSetRecoilState } from "recoil";
import { getMerchants } from "../../../api/Merchants";
import { MerchantsParams } from "../../../api/Merchants/types";
import { getMerchantNames, getPaymentsTotal } from "../../../api/v2/Payments";
import { PaymentsDetailParams } from "../../../api/v2/Payments/types";
import ClientLayout from "../../../components/Layout/Client/Layout";
import LoadingButton from "../../../components/LoadingButton";
import CardTotal from "../../../components/Payments/CardsTotal";
import TransactionDetail from "../../../components/Payments/TransactionDetail";
import TransactionTotal from "../../../components/Payments/TransactionTotal";
import Title from "../../../components/Title";
import useRenewal from "../../../hooks/useRenewal";
import registerImg from "../../../images/counseling_img.png";
import { getDate } from "../../../libs/get-date";
import { getToday } from "../../../libs/get-today";
import isModalOpenAtom from "../../../recoil/isModalOpen";
import PaymentsState, {
  PaymentsDetailState
} from "../../../recoil/payments/atom";

const CARD_COMPANY = [
  { name: "전체", value: "기본" },
  // { name: "ISP", value: "ISP" },
  { name: "신한카드", value: "신한카드" },
  { name: "현대카드", value: "현대카드" },
  { name: "삼성카드", value: "삼성카드" },
  { name: "우리카드", value: "우리카드" },
  { name: "KB국민카드", value: "KB국민카드" },
  { name: "롯데카드", value: "롯데카드" },
  { name: "NH농협카드", value: "NH농협카드" },
  { name: "하나카드", value: "하나카드" },
  { name: "비씨카드", value: "비씨카드" },
  { name: "씨티카드", value: "씨티카드" }
  // { name: "카카오뱅크", value: "카카오뱅크" },
  // { name: "KDB산업은행", value: "KDB산업은행" },
  // { name: "SH수협은행", value: "SH수협은행" },
  // { name: "전북은행", value: "전북은행" },
  // { name: "우체국예금보험", value: "우체국예금보험" },
  // { name: "새마을금고", value: "새마을금고" },
  // { name: "저축은행SB", value: "저축은행SB" },
  // { name: "제주은행", value: "제주은행" },
  // { name: "광주은행", value: "광주은행" },
  // { name: "신협", value: "신협" },
  // { name: "JCB", value: "JCB" },
  // { name: "유니온페이", value: "유니온페이" },
  // { name: "마스터카드", value: "마스터카드" },
  // { name: "비자카드", value: "비자카드" },
  // { name: "다이너스클럽", value: "다이너스클럽" },
  // { name: "디스커버", value: "디스커버" }
];

interface FormInputs {
  startDate: string;
  endDate: string;
  cardCompany: string;
  status: string;
  mallId: string;
  mallName: string;
  paymentKey: string;
  transactionKey: string;
  approvalNum: string;
  purchaseStatus: string;
  merchantGrade: string;
}

const Payment = () => {
  // ===============================================================================================
  // 리코일 스테이트
  // ===============================================================================================
  const setIsModalOpen = useSetRecoilState(isModalOpenAtom);
  const setPaymentsDetail = useSetRecoilState(PaymentsDetailState);
  const [paymentsTotal, setPaymentsTotal] = useRecoilState(PaymentsState);

  // 가맹점명 입력값 스테이트
  const [searchTerm, setSearchTerm] = useState("");

  // 가맹점 목록 조회 파라미터 스테이트
  const [params, setParams] = useState<MerchantsParams>({
    mallId: "",
    mallName: "",
    page: 0,
    size: 50,
    status: "계약승인"
  });

  const defaultFormValues = {
    startDate: getToday(),
    endDate: getToday(),
    cardCompany: "기본",
    status: "DEFAULT",
    mallId: "",
    mallName: "",
    transactionKey: "",
    paymentKey: "",
    approvalNum: "",
    purchaseStatus: "DEFAULT",
    merchantGrade: ""
  };

  const { isRenewalTokenLoading, mutateRenewalToken } = useRenewal();

  // ===============================================================================================
  // 리액트 훅 폼
  // ===============================================================================================
  const { register, handleSubmit, control, reset } = useForm<FormInputs>({
    defaultValues: {
      ...defaultFormValues
    }
  });
  // 조회 필터 리셋
  const handleReset = () => {
    reset(defaultFormValues);
  };
  // 리코일 스테이트 초기화
  useEffect(() => {
    setPaymentsTotal(defaultFormValues);
    setPaymentsDetail({
      ...defaultFormValues,
      merchantGrade: "",
      page: 0,
      size: 50,
      sort: ""
    });
  }, [setPaymentsDetail, setPaymentsTotal]);

  // ===============================================================================================
  // 리액트 쿼리
  // ===============================================================================================
  // 현재 운영중인 가맹점 목록 조회
  const { data: getMerchantsNameData } = useQuery(
    ["/merchants/names"],
    () => getMerchantNames(searchTerm), // 검색어 전달
    { enabled: !!searchTerm } // 입력값이 있는 경우에만 요청 활성화
  );

  // ===============================================================================================
  // 가맹점 목록 조회 - 등록된 가맹점 유무 확인
  // ===============================================================================================
  const { data, isLoading } = useQuery(
    ["/merchants", params],
    () => getMerchants(params),
    { retry: false }
  );

  // 가맹점별 총합계 조회
  const { data: totalData } = useQuery(
    ["/payments/total", paymentsTotal],
    () => getPaymentsTotal(paymentsTotal),
    {
      onError: (error: any) => {
        setIsModalOpen({
          value: true,
          position: "top",
          alertSeverity: "error",
          message: error?.response?.data?.message
        });
      },
      refetchOnWindowFocus: false,
      select: (data) => ({
        ...data,
        content: data.content.map((data: any) => ({
          ...data,
          mallId: data.mallId,
          createdAt: data.datePeriod,
          price: data.transactionTotal
        }))
      }),
      onSuccess: () => {
        if (isRenewalTokenLoading) return;
        mutateRenewalToken();
      }
    }
  );

  const handleInputChange = (event: any) => {
    setSearchTerm(event.target.value);
  };

  // 조회
  const handleForm = (data: FormInputs) => {
    const isInvalidData =
      data.startDate?.includes("NaN") || data.endDate?.includes("NaN");
    const isInvaildPeriod = data.startDate > data.endDate;
    if (isInvalidData || isInvaildPeriod) {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: "거래 일자를 확인해주세요."
      });
      return;
    }
    const formattedData = {
      ...data,
      merchantGrade: data.merchantGrade === "전체" ? "" : data.merchantGrade
    };
    setPaymentsDetail((prevValue: PaymentsDetailParams) => ({
      ...prevValue,
      ...formattedData,
      page: 0
    }));

    setPaymentsTotal(formattedData);
  };

  return (
    <>
      <Title title="거래" />
      <ClientLayout>
        {data && data?.content.length > 0 ? (
          <Stack spacing={3}>
            <Paper
              sx={{
                p: 3,
                border: "1px solid #F2F3F5",
                borderRadius: 3
              }}
            >
              <form onSubmit={handleSubmit(handleForm)}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={2}>
                    <LocalizationProvider
                      adapterLocale={koLocale}
                      dateAdapter={AdapterDateFns}
                    >
                      <Controller
                        name="startDate"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <DatePicker
                            label="거래 시작 날짜"
                            inputFormat="yyyy-MM-dd"
                            value={value}
                            onChange={(value) => onChange(getDate(value))}
                            renderInput={(param) => (
                              <TextField
                                fullWidth
                                size="small"
                                required
                                {...param}
                              />
                            )}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <LocalizationProvider
                      adapterLocale={koLocale}
                      dateAdapter={AdapterDateFns}
                    >
                      <Controller
                        name="endDate"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <DatePicker
                            label="거래 종료 날짜"
                            inputFormat="yyyy-MM-dd"
                            value={value}
                            onChange={(value) => onChange(getDate(value))}
                            renderInput={(param) => (
                              <TextField
                                fullWidth
                                size="small"
                                required
                                {...param}
                              />
                            )}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <Controller
                      name="cardCompany"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          id="input-card-company"
                          label="카드사"
                          required
                          fullWidth
                          size="small"
                          select
                          value={value}
                          onChange={onChange}
                        >
                          {CARD_COMPANY.map((c) => (
                            <MenuItem key={c.value} value={c.value}>
                              {c.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <Controller
                      name="status"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          id="input-status"
                          label="거래 상태"
                          required
                          fullWidth
                          size="small"
                          select
                          value={value}
                          onChange={onChange}
                        >
                          <MenuItem value="DEFAULT">전체</MenuItem>
                          <MenuItem value="COMPLETE">승인</MenuItem>
                          <MenuItem value="CANCEL">취소</MenuItem>
                        </TextField>
                      )}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Controller
                      name="merchantGrade"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          label="거래 등급"
                          required
                          fullWidth
                          size="small"
                          select
                          value={value || "전체"}
                          onChange={onChange}
                        >
                          <MenuItem value="전체">전체</MenuItem>
                          <MenuItem value="일반">일반</MenuItem>
                          <MenuItem value="영세">영세</MenuItem>
                          <MenuItem value="중소1">중소1</MenuItem>
                          <MenuItem value="중소2">중소2</MenuItem>
                          <MenuItem value="중소3">중소3</MenuItem>
                        </TextField>
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} md={2}>
                    <Stack
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="center"
                      spacing={1}
                    >
                      <LoadingButton
                        icon={<SearchIcon />}
                        size="medium"
                        fullWidth={false}
                        loading={false}
                      >
                        조회
                      </LoadingButton>
                      <Button
                        variant="outlined"
                        endIcon={<RestartAltIcon />}
                        onClick={handleReset}
                      >
                        초기화
                      </Button>
                    </Stack>
                  </Grid>

                  <Grid item xs={12} md={2}>
                    <Autocomplete
                      id="input-mallName"
                      options={getMerchantsNameData?.content ?? []}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          id="input-mallName"
                          label="가맹점명"
                          inputProps={{
                            ...params.inputProps
                          }}
                          fullWidth
                          size="small"
                          {...register("mallName")}
                          onChange={handleInputChange}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <TextField
                      id="input-mallId"
                      label="가맹점 ID"
                      fullWidth
                      size="small"
                      {...register("mallId")}
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <TextField
                      id="input-approvalNum"
                      label="승인 번호"
                      fullWidth
                      size="small"
                      {...register("approvalNum")}
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <TextField
                      id="input-transactionKey"
                      label="거래 KEY"
                      fullWidth
                      size="small"
                      {...register("transactionKey")}
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <TextField
                      id="input-paymentKey"
                      label="거래고유 KEY"
                      fullWidth
                      size="small"
                      {...register("paymentKey")}
                    />
                  </Grid>
                </Grid>
              </form>
            </Paper>

            {/* 가맹점별 합계 */}
            <TransactionTotal data={totalData} />

            {/* 카드사별 합계 */}
            <CardTotal />

            {/* 거래 상세 조회  */}
            <TransactionDetail />
          </Stack>
        ) : (
          <Paper>
            {isLoading ? (
              <>
                <Backdrop open={isLoading}>
                  <CircularProgress
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      ml: 25
                    }}
                  />
                </Backdrop>
              </>
            ) : (
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"center"}
                sx={{ height: 800 }}
                spacing={25}
              >
                <Box textAlign={"center"}>
                  <Typography variant="h6" gutterBottom>
                    가맹 등록 및 계약 완료 후 확인이 가능합니다.
                  </Typography>
                  <Link href="/registrations" underline="none">
                    <Button sx={{ fontSize: 16 }}>가맹 등록 하기</Button>
                  </Link>
                </Box>
                <Box component="img" src={registerImg} sx={{ width: "30%" }} />
              </Stack>
            )}
          </Paper>
        )}
      </ClientLayout>
    </>
  );
};

export default Payment;
export { CARD_COMPANY };
