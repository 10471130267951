import CloseIcon from "@mui/icons-material/Close";
import RemoveCircleOutlineRoundedIcon from "@mui/icons-material/RemoveCircleOutlineRounded";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useRecoilValue, useSetRecoilState } from "recoil";
import isModalOpen from "../../../../../recoil/isModalOpen/atom";
import loginInfoAtom from "../../../../../recoil/loginInfo";
import { HelperTextMessage } from "../../../../components/HelperTextMessage";
import { getInChargeInfo, postSettlementPdfExcel } from "../libs";

interface Props {
  idList: number[];
  closeModal?: () => void;
}

const SendPdfExcelModal = ({ idList, closeModal }: Props) => {
  // ===================================================================================================================
  // 리코일 스테이트
  // ===================================================================================================================
  const setIsModalOpen = useSetRecoilState(isModalOpen);

  // 로그인 유저 정보
  const loginInfo = useRecoilValue(loginInfoAtom);
  const userName = loginInfo.loginUser.name;

  // ===================================================================================================================
  // 컴포넌트 스테이트
  // ===================================================================================================================
  const [emailList, setEmailList] = useState<string[]>([]);

  // ===================================================================================================================
  // 리액트 훅 폼
  // ===================================================================================================================
  const {
    register,
    handleSubmit,
    getValues,
    reset,
    trigger,
    watch,
    formState: { errors }
  } = useForm({
    mode: "onChange",
    defaultValues: {
      email: "",
      content: `안녕하세요.\n오직페이 담당자 ${userName}입니다.\n\n정산 내역서 및 거래내역 파일 전달드립니다.\n\n감사합니다.\n\n\n\n${userName} 드림`
    }
  });

  const params = {
    merchantIdList: idList.join(","),
    roleStatus: "1"
  };

  // ===================================================================================================================
  // 리액트 쿼리
  // ===================================================================================================================
  // 담당자 조회
  const { data: inChargeData } = useQuery(
    ["/merchant-in-charge-informations", params],
    () => getInChargeInfo(params),
    {
      onSuccess: (data) => {
        setEmailList(data?.content.map((item: any) => item.email) || []);
      },
      onError: (error: any) => {
        setIsModalOpen({
          value: true,
          position: "top",
          alertSeverity: "error",
          message: error?.response?.data?.message
        });
      }
    }
  );

  // 정산내역서 이메일 전송
  const { mutate, isLoading } = useMutation(postSettlementPdfExcel, {
    onSuccess: () => {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "success",
        message: "이메일 전송이 완료되었습니다."
      });
    },
    onError: (error: any) => {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: error?.response?.data?.message
      });
    }
  });

  // ===================================================================================================================
  // 정산내역서 이메일 전송
  // ===================================================================================================================
  const onSubmit = () => {
    if (idList.length === 0) {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: "id 리스트가 없습니다."
      });
      return;
    }

    // 줄 바꿈을 HTML 형식으로 변환
    const formattedContent = watch("content").replace(/\n/g, "<br />");

    const params = {
      idList: idList,
      emailList: emailList,
      content: formattedContent
    };

    closeModal?.();
    mutate(params);
  };

  // ===================================================================================================================
  // 이메일 추가/삭제
  // ===================================================================================================================
  const addEmail = async () => {
    const isEmailValid = await trigger("email"); // 이메일 유효성 검사 실행
    const email = getValues("email");

    if (isEmailValid && email && !emailList.includes(email)) {
      setEmailList((prevList) => [...prevList, email]);
      reset({ email: "" }); // 입력 필드 초기화
    } else {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: "이미 등록된 이메일입니다."
      });
      reset({ email: "" });
    }
  };

  // 이메일 삭제
  const handleDelete = (index: number) => {
    setEmailList((prevList) => prevList.filter((_, i) => i !== index));
  };

  const emailValue = watch("email");
  const isEmailValid = !errors.email && emailValue;

  // 등록된 이메일이 없고 이메일 양식이 없는 경우
  const isSubmitDisabled = emailList.length === 0 || watch("content") === "";

  return (
    <>
      <Dialog open={true} fullWidth maxWidth="xs">
        <DialogTitle>정산 내역서 및 개별 내역 이메일 전송</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={closeModal}
          sx={{
            position: "absolute",
            right: 8,
            top: 12
          }}
        >
          <CloseIcon />
        </IconButton>

        {/* 전송중 = 로딩중일 때 */}
        {/* {<Loading isLoading={isLoading} />} */}

        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <Typography sx={{ mb: 1.5 }}>이메일을 등록해주세요.</Typography>
            <Stack direction={"row"} gap={2} sx={{ mb: 2 }}>
              <TextField
                label="이메일"
                {...register("email", {
                  pattern: {
                    value:
                      /^([0-9a-zA-Z_.-]+)@([0-9a-zA-Z_-]+)(\.[0-9a-zA-Z_-]+){1,2}$/,
                    message: "형식에 맞지 않는 이메일 주소입니다."
                  }
                })}
                fullWidth
                size="small"
                helperText={
                  <HelperTextMessage>{errors.email?.message}</HelperTextMessage>
                }
              />

              {/* <Autocomplete
                freeSolo
                fullWidth
                options={inChargeData?.content?.map((item: any) => item.email) ?? []}
                inputValue={emailInput}
                onInputChange={(_, newValue) => setEmailInput(newValue)}
                renderInput={(params) => (
                  <TextField
                    {...register("email", {
                      pattern: {
                        value:
                          /^([0-9a-zA-Z_.-]+)@([0-9a-zA-Z_-]+)(\.[0-9a-zA-Z_-]+){1,2}$/,
                        message: "형식에 맞지 않는 이메일 주소입니다."
                      }
                    })}
                    {...params}
                    label="이메일 입력 또는 선택"
                    error={!!errors.email}
                    helperText={errors.email?.message}
                    size="small"
                  />
                )}
              /> */}

              <Button
                variant="outlined"
                sx={{ width: 140, height: 40 }}
                onClick={addEmail}
                disabled={!isEmailValid}
              >
                이메일 등록
              </Button>
            </Stack>

            {/* 이메일 등록 */}
            <Stack sx={{ px: 1 }}>
              {emailList.map((email, index) => (
                <Stack
                  key={index}
                  direction={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Typography>{email}</Typography>
                  <IconButton onClick={() => handleDelete(index)}>
                    <RemoveCircleOutlineRoundedIcon />
                  </IconButton>
                </Stack>
              ))}
            </Stack>

            {/* 이메일 양식 */}
            <TextField
              label="이메일양식"
              {...register("content")}
              fullWidth
              required
              multiline
              size="small"
              helperText={
                <HelperTextMessage>{errors.content?.message}</HelperTextMessage>
              }
              sx={{ mt: 2 }}
            />
          </DialogContent>
          <DialogActions sx={{ mx: 2, mb: 1 }}>
            <Button
              type="submit"
              variant="contained"
              fullWidth
              sx={{ color: "white" }}
              disabled={isSubmitDisabled}
            >
              전송 완료
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};
export default SendPdfExcelModal;
