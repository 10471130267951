import ErrorOutline from "@mui/icons-material/ErrorOutline";
import { Autocomplete, CircularProgress } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import Paper from "@mui/material/Paper";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useCallback, useEffect, useRef, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useSetRecoilState } from "recoil";
import {
  getBankList,
  getBusinessNumber,
  merchantFileUpload,
  postMerchant,
  postMerchantRegistrationTemp
} from "../../api/MerchantRegistration";
import { Bank, StepData } from "../../api/MerchantRegistration/types";
import { FileUploadForm } from "../../libs/file-upload";
import { engValidate, numberValidate } from "../../libs/input-validation";
import isModalOpenAtom from "../../recoil/isModalOpen";
import { CountryList } from "../CountryList";
import PostCode from "../PostCode";
import { uploadFiles } from "../../libs/file-mapping";
import Loading from "../Loadings/Loading";
import { HelperTextMessage } from "../../pages/components/HelperTextMessage";

interface Props {
  tempData: StepData;
  nextEvent: ({ currentStep }: StepData) => void;
}

interface Form {
  name?: string;
  nameEn?: string;
  birth?: string;
  nationality?: string;
  postCode?: string;
  address?: string;
  detailAddress?: string;
  phone?: string;
}

interface ManagerForm {
  name?: string;
  role?: string;
  email?: string;
  phone?: string;
}

interface BusinessCondition {
  name: string;
}

interface MerchantCategory {
  name: string;
}

export interface MerchantFormData {
  // 사업자 정보
  currentStep: number;
  isCheckedAddress: boolean;
  mallName: string;
  mallNameEn: string;
  businessType: string;
  businessName: string;
  registrationNumber: string;
  corporationNumber: string;
  postCode: string;
  mallAddress: string;
  detailAddress: string;
  headOfficePostCode: string;
  headOfficeAddress: string;
  headOfficeDetailAddress: string;
  phone: string;
  fax: string;
  mailOrderSalesNumber: string;
  mallUrl: string;
  mallIp: string;
  introductionCompany: string;
  testId: string;
  testPassword: string;
  merchantCategory: MerchantCategory[];
  businessConditions: BusinessCondition[];
  isCheckedTestId: boolean;

  // 대표자 정보
  merchantOwnerInformation: Form[];

  // 은행 정보
  bankName: string;
  accountCode: string;
  accountHolder: string;

  // 담당자 정보
  isChecked: boolean;
  isCheckedTax: boolean;
  merchantInChargeInformation: ManagerForm[];

  // 파일 정보 - 개인사업자
  businessLicense: string;
  bankBookCopy: string;
  sealCertificate: string;
  idCardFront: string;
  idCardBack: string;
  gradeConfirmation: string;

  // 파일 정보 - 법인사업자
  corporateRegistration: string;
  actualOwnerConfirmation: string;
  corporateSealCertificate: string;
  사용인감계: string;
}

const MerchantInformations = ({ tempData, nextEvent }: Props) => {
  // ===================================================================================================================
  // 리코일 스테이트
  // ===================================================================================================================
  const setIsModalOpen = useSetRecoilState(isModalOpenAtom);

  // ===================================================================================================================
  // 스테이트
  // ===================================================================================================================
  // 사업자 정보 주소 스테이트
  const [openPostCode, setOpenPostCode] = useState({
    isOpen: false,
    referenceIndex: 0
  });

  // 대표자 주소 스테이트
  const [openOwnerPostCode, setOpenOwnerPostCode] = useState({
    isOpen: false,
    referenceIndex: 0
  });

  // 주소 체크박스 스테이트
  const [isCheckedAddress, setIsCheckedAddress] = useState(
    tempData.isCheckedAddress ?? false
  );

  // 체크박스 스테이트
  const [isCheckedTestId, setIsCheckedTestId] = useState(false);

  // 은행 정보 스테이트
  const [selectedBank, setSelectedBank] = useState<Bank>({
    code: "",
    name: ""
  });

  // 담당자 정보 체크박스 스테이트
  const [isChecked, setIsChecked] = useState(tempData.isChecked ?? false);
  // 세금 계산 담당자 체크박스 스테이트
  const [isCheckedTax, setIsCheckedTax] = useState(
    tempData.isCheckedTax ?? false
  );

  // 파일 데이터 스테이트
  const [fileData, setFileData] = useState<FileUploadForm>({
    // 개인 사업자
    businessLicense: undefined,
    bankBookCopy: undefined,
    sealCertificate: undefined,
    idCardFront: undefined,
    idCardBack: undefined,
    gradeConfirmation: undefined,
    // 법인사업자
    corporateRegistration: undefined,
    actualOwnerConfirmation: undefined,
    corporateSealCertificate: undefined
  });

  // 업종, 업태 ref
  const businessCategoryRef = useRef<HTMLInputElement | null>(null);
  const businessConditionsRef = useRef<HTMLInputElement | null>(null);

  // 업태, 업종값 스테이트
  const [conditionValue, setConditionValue] = useState("");
  const [categoryValue, setCategoryValue] = useState("");

  // ===================================================================================================================
  // 리액트 쿼리
  // ===================================================================================================================
  // 임시저장
  const { mutate } = useMutation(postMerchantRegistrationTemp, {
    onSuccess: () => {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "success",
        message: "임시 저장이 완료 되었습니다."
      });
    },
    onError: (error: any) => {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: error?.response?.data?.message
      });
    }
  });

  // 파일 업로드
  // `mutateAsync`는 Promise를 반환하는 비동기 함수다. `await`를 사용해 작업이 완료될 때 까지 기다릴 수 있다.
  const { mutateAsync: fileMutate, isLoading: fileLoading } = useMutation(
    merchantFileUpload,
    {
      onError: (error: any) => {
        setIsModalOpen({
          value: true,
          position: "top",
          alertSeverity: "error",
          message: error.message
        });
      }
    }
  );

  // 가맹점 등록
  const { mutate: registMutate, isLoading } = useMutation(postMerchant, {
    onSuccess: () => {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "success",
        message: "가맹등록 저장이 완료 되었습니다."
      });
      nextEvent({ currentStep: 2 });
    },
    onError: (error: any) => {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: error.response.data.message
      });
    }
  });

  // 은행 리스트 가져오기
  const { data: bankNameList } = useQuery(["getBankList"], () => getBankList());

  // ===================================================================================================================
  // 리액트 훅 폼
  // ===================================================================================================================
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    setFocus,
    watch,
    clearErrors,
    setError,
    control
  } = useForm<MerchantFormData>({
    defaultValues: {
      // 사업자 정보
      mallName: tempData?.mallName ?? "",
      mallNameEn: tempData?.mallNameEn ?? "",
      businessType: tempData?.merchantInformation?.businessType ?? "개인",
      businessName: tempData.merchantInformation?.businessName ?? "",
      registrationNumber:
        tempData?.merchantInformation?.registrationNumber ?? "",
      corporationNumber: tempData?.merchantInformation?.corporationNumber ?? "",
      postCode: tempData?.merchantInformation?.postCode ?? "",
      mallAddress: tempData?.merchantInformation?.mallAddress ?? "",
      detailAddress: tempData?.merchantInformation?.detailAddress ?? "",
      headOfficePostCode:
        tempData?.merchantInformation?.headOfficePostCode ?? "",
      headOfficeAddress: tempData?.merchantInformation?.headOfficeAddress ?? "",
      headOfficeDetailAddress:
        tempData?.merchantInformation?.headOfficeDetailAddress ?? "",
      phone: tempData?.merchantInformation?.phone ?? "",
      fax: tempData?.merchantInformation?.fax ?? "",
      mailOrderSalesNumber:
        tempData.merchantInformation?.mailOrderSalesNumber ?? "",
      mallUrl: tempData.mallUrl ?? "",
      mallIp: tempData.mallIp ?? "",
      introductionCompany:
        tempData.merchantInformation?.introductionCompany ?? "",
      testId: tempData.merchantInformation?.testId ?? "",
      testPassword: tempData.merchantInformation?.testPassword ?? "",
      merchantCategory:
        tempData.merchantInformation?.merchantCategory
          ?.split(" | ")
          .map((name) => ({ name })) ?? [],
      businessConditions:
        tempData.merchantInformation?.businessConditions
          ?.split(" | ")
          .map((name) => ({ name })) ?? [],

      // 대표자 정보
      merchantOwnerInformation:
        tempData.merchantOwnerInformation &&
        tempData.merchantOwnerInformation.length > 0
          ? tempData.merchantOwnerInformation
          : [
              {
                name: "",
                birth: "",
                nationality: "대한민국",
                postCode: "",
                address: "",
                detailAddress: "",
                phone: ""
              }
            ],

      // 은행 정보
      bankName: tempData?.merchantBankInformation?.bankName ?? "",
      accountCode: tempData?.merchantBankInformation?.accountCode ?? "",
      accountHolder: tempData?.merchantBankInformation?.accountHolder ?? "",

      // 담당자 정보
      merchantInChargeInformation: tempData.merchantInChargeInformation,

      // 파일 정보 - 개인사업자
      businessLicense: "",
      bankBookCopy: "",
      sealCertificate: "",
      idCardFront: "",
      idCardBack: "",
      gradeConfirmation: "",

      // 파일 정보 - 법인사업자
      corporateRegistration: "",
      actualOwnerConfirmation: "",
      corporateSealCertificate: "",
      사용인감계: ""
    },
    mode: "onBlur"
  });

  // ===================================================================================================================
  // 대표자 양식 추가, 삭제 - useFieldArray
  // ===================================================================================================================
  const { fields, append, remove } = useFieldArray<MerchantFormData>({
    control,
    name: "merchantOwnerInformation"
  });

  // ===================================================================================================================
  // 업종, 업태 추가 삭제 - useFieldArray
  // ===================================================================================================================
  // 업태 useFieldArray
  const {
    fields: businessConditionsFields,
    append: appendBusinessConditions,
    remove: removeBusinessConditions
  } = useFieldArray<MerchantFormData>({
    control,
    name: "businessConditions"
  });

  // 업종 useFieldArray
  const {
    fields: merchantCategoryFields,
    append: appendMerchantCategory,
    remove: removeMerchantCategory
  } = useFieldArray<MerchantFormData>({
    control,
    name: "merchantCategory"
  });

  // ===================================================================================================================
  // 데이터 임시저장, 사업자 번호 검증
  // ===================================================================================================================
  const { refetch } = useQuery(
    [`/bypass/business-number/validate`, watch("registrationNumber")],
    () => getBusinessNumber(watch("registrationNumber")),
    {
      enabled: false,
      retry: false,
      onError: (error: any) => {
        setIsModalOpen({
          value: true,
          position: "top",
          alertSeverity: "error",
          message: error?.response?.data?.message
        });
        setFocus("registrationNumber");
      }
    }
  );

  // ===================================================================================================================
  // 가맹 등록
  // ===================================================================================================================
  const onSubmit = async (formData: MerchantFormData) => {
    if (isLoading) return;

    // 사업자 번호 검증
    // 리액트 쿼리를 실행시키기 위한 "refetch"
    const { isError } = await refetch();
    if (isError) return;

    // 업태 및 업종 검증
    if (businessConditionsFields.length === 0) {
      setError("businessConditions", {
        type: "manual",
        message: "업태는 최소 1개 이상이어야 합니다."
      });
      businessConditionsRef.current?.scrollIntoView({ behavior: "smooth" });
      return;
    }

    if (merchantCategoryFields.length === 0) {
      setError("merchantCategory", {
        type: "manual",
        message: "업종은 최소 1개 이상이어야 합니다."
      });
      businessCategoryRef.current?.scrollIntoView({ behavior: "smooth" });
      return;
    }

    // 대표자 정보 묶어서 보내기
    const formMerchantOwnerInfo =
      formData.merchantOwnerInformation?.map((info) => {
        const fullAddress = `${info.postCode} | ${info.address} | ${info.detailAddress}`;
        const year = info?.birth?.substring(0, 4);
        const month = info?.birth?.substring(4, 6);
        const day = info?.birth?.substring(6, 8);
        const birthString = `${year}-${month}-${day}`;

        return {
          address: fullAddress,
          birth: birthString,
          name: info.name,
          nameEn: info.nameEn,
          nationality: info.nationality,
          phone: info.phone
        };
      }) ?? [];

    let newHeadOfficeAddress: string | undefined;
    if (formData.headOfficePostCode) {
      newHeadOfficeAddress = `${formData.headOfficePostCode} | ${formData.headOfficeAddress} | ${formData.headOfficeDetailAddress}`;
    } else {
      newHeadOfficeAddress = undefined;
    }

    const merchantFileDto = await uploadFiles(
      fileData,
      formData.businessType,
      fileMutate
    );

    // merchantFileDto를 포함한 최종 폼 데이터
    const newFormData = {
      mallIp: formData.mallIp,
      mallName: formData.mallName,
      mallNameEn: formData.mallNameEn,
      mallUrl: formData.mallUrl,
      merchantInformation: {
        address: `${formData.postCode} | ${formData.mallAddress} | ${formData.detailAddress}`,
        headOfficeAddress: newHeadOfficeAddress,
        merchantCategory: merchantCategoryFields
          .map((condition) => condition.name ?? "")
          .filter(Boolean)
          .join(" | "),
        businessConditions: businessConditionsFields
          .map((condition) => condition.name ?? "")
          .filter(Boolean)
          .join(" | "),
        businessType: formData.businessType,
        businessName: formData.businessName,
        corporationNumber: formData.corporationNumber,
        fax: formData.fax,
        introductionCompany: formData.introductionCompany,
        mailOrderSalesNumber: formData.mailOrderSalesNumber,
        phone: formData.phone,
        registrationNumber: formData.registrationNumber,
        testId: formData.testId,
        testPassword: formData.testPassword
      },
      merchantBankInformation: {
        accountCode: formData.accountCode,
        accountHolder: formData.accountHolder,
        bankCode: selectedBank.code,
        bankName: selectedBank.name
      },
      merchantInChargeInformation: [
        {
          email: formData.merchantInChargeInformation[0].email,
          name: formData.merchantInChargeInformation[0].name,
          phone: formData.merchantInChargeInformation[0].phone,
          role: "계약"
        },
        {
          email: formData.merchantInChargeInformation[1].email,
          name: formData.merchantInChargeInformation[1].name,
          phone: formData.merchantInChargeInformation[1].phone,
          role: "정산"
        },
        {
          email: formData.merchantInChargeInformation[2].email,
          name: formData.merchantInChargeInformation[2].name,
          phone: formData.merchantInChargeInformation[2].phone,
          role: "계산서"
        }
      ],
      merchantOwnerInformation: [...formMerchantOwnerInfo],
      merchantFileDto: merchantFileDto
    };

    // 최종 가맹점 등록 호출
    registMutate(newFormData);
  };

  // 데이터 임시저장 버튼
  const onClickTempSave = () => {
    const stepData = {
      isCheckedAddress,
      mallName: getValues("mallName"),
      mallNameEn: getValues("mallNameEn"),
      mallUrl: getValues("mallUrl"),
      mallIp: getValues("mallIp"),
      merchantInformation: {
        ...getValues(),
        merchantCategory: merchantCategoryFields
          .map((condition) => condition.name ?? "")
          .filter(Boolean)
          .join(" | "),
        businessConditions: businessConditionsFields
          .map((condition) => condition.name ?? "")
          .filter(Boolean)
          .join(" | ")
      },
      merchantOwnerInformation: [...getValues("merchantOwnerInformation")],
      merchantBankInformation: {
        ...getValues(),
        businessName: getValues("businessName"),
        bankName: selectedBank.name,
        bankCode: selectedBank.code
      },
      isChecked,
      isCheckedTax,
      merchantInChargeInformation: [...getValues("merchantInChargeInformation")]
    };
    if (
      !stepData.merchantInformation.mallName ||
      !stepData.merchantInformation.registrationNumber
    ) {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: "가맹점명과 사업자등록번호는 반드시 입력해주세요."
      });
      return;
    }
    mutate({
      id: tempData?.id ?? 0,
      userId: tempData?.userId ?? 0,
      isUse: true,
      jsonString: JSON.stringify(stepData),
      registrant: "CLIENT"
    });
  };

  // ===================================================================================================================
  // 주소 검색 모달창
  // ===================================================================================================================
  // 가맹점 주소 검색 모달창 열기
  const openPostCodeModal = (index: number) => {
    setOpenPostCode({
      isOpen: true,
      referenceIndex: index
    });
  };
  // 대표자 주소 검색 모달창 열기
  const openOwnerPostCodeModal = (index: number) => {
    setOpenOwnerPostCode({
      isOpen: true,
      referenceIndex: index
    });
  };
  // 인풋에 검색된 주소값 입력
  const postCodeHandler = (data: any, index?: number) => {
    if (index === 0) {
      setValue("postCode", data.zonecode);
      setValue("mallAddress", data.address);
      clearErrors("postCode");
      clearErrors("mallAddress");
    } else {
      setValue("headOfficePostCode", data.zonecode);
      setValue("headOfficeAddress", data.address);
    }
  };
  // 검색된 주소값 대표자 주소 인풋에 담기
  const ownerAddressHandler = (data: any, index?: number) => {
    if (index === undefined) return;
    setValue(`merchantOwnerInformation.${index}.postCode`, data.zonecode);
    setValue(`merchantOwnerInformation.${index}.address`, data.address);
    clearErrors(`merchantOwnerInformation.${index}.postCode`);
    clearErrors(`merchantOwnerInformation.${index}.address`);
  };

  // 개인/법인 구분
  const currentBusinessType = watch("businessType");

  // ===================================================================================================================
  // 체크박스 내용 붙여넣기
  // ===================================================================================================================
  const checkBox = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsCheckedAddress(e.target.checked);
    if (e.target.checked) {
      const getPostCode = getValues("postCode");
      setValue("headOfficePostCode", getPostCode);
      const getAddress = getValues("mallAddress");
      setValue("headOfficeAddress", getAddress);
      const getDetailAddress = getValues("detailAddress");
      setValue("headOfficeDetailAddress", getDetailAddress);
    }
  };

  // ===================================================================================================================
  // 테스트용 ID, 비밀번호 체크박스
  // ===================================================================================================================
  const watchTestId = watch("testId");

  useEffect(() => {
    if (watchTestId !== "비회원 결제가능") {
      setIsCheckedTestId(false);
    } else {
      setIsCheckedTestId(true);
    }
  }, [watchTestId]);

  // 테스트용 아이디/비밀번호 체크박스 핸들러
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsCheckedTestId(event.target.checked);
    if (event.target.checked) {
      setValue("testId", "비회원 결제가능");
      setValue("testPassword", "비회원 결제가능");
      clearErrors("testId");
      clearErrors("testPassword");
    } else {
      setValue("testId", "");
      setValue("testPassword", "");
    }
  };

  // ===========================================================================
  // 화면 불러 왔을 때
  // - stepData 에서 bankName 과 bankCode 를 가져온다
  // - 가져온 bankName, bankCode 를 bank 객체로 만든다
  // - bank 객체는 selectedBank state 에 넣는다
  // ===========================================================================
  useEffect(() => {
    if (!bankNameList || bankNameList.content.length < 1) return;
    const bankName = tempData?.merchantBankInformation?.bankName ?? "";
    const bankCode = tempData?.merchantBankInformation?.bankCode ?? "";

    setSelectedBank({
      name: bankName,
      code: bankCode
    });
  }, [tempData, bankNameList]);

  // ===================================================================================================================
  // 은행 선택
  // - 이벤트에서 밸류(뱅크네임)를 가져온다
  // - 뱅크네임으로 뱅크 리스트에서 뱅크 오브젝트를 찾는다
  // - 뱅크 오브젝트를 스테이트에 할당한다
  // ===================================================================================================================
  const bankSelectHandler = (event: any) => {
    const selectedBankName = event.target.value;

    if (!bankNameList) return;

    const selectedBank = bankNameList.content.find(
      (db) => db.name === selectedBankName
    );

    if (!selectedBank) return;
    setSelectedBank(selectedBank);
    setValue("bankName", selectedBank.name);
    clearErrors("bankName");
  };

  // ===================================================================================================================
  // 업종
  // ===================================================================================================================
  const handleAddMerchantCategory = () => {
    if (!categoryValue.trim()) return;
    if (merchantCategoryFields.length >= 5) {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: "업종은 최대 5개까지만 등록 가능합니다."
      });
      return;
    }
    appendMerchantCategory({ name: categoryValue.trim() });
    setCategoryValue("");
  };

  // 업태 삭제 핸들러
  const handleDeleteMerchantCategory = (index: number) => {
    removeMerchantCategory(index);
  };

  // ===================================================================================================================
  // 업태
  // ===================================================================================================================
  const handleAddBusinessCondition = () => {
    if (!conditionValue.trim()) return;
    if (businessConditionsFields.length >= 5) {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: "업태는 최대 5개까지만 등록 가능합니다."
      });
      return;
    }
    appendBusinessConditions({ name: conditionValue.trim() });
    setConditionValue("");
  };

  // 업태 삭제 핸들러
  const handleDeleteBusinessCondition = (index: number) => {
    removeBusinessConditions(index);
  };

  // ===================================================================================================================
  // 체크박스 내용 붙여넣기
  // ===================================================================================================================
  // 관리자 체크박스
  const managerCheckBox = (e: any) => {
    setIsChecked(e.target.checked);
    if (e.target.checked) {
      const getName = getValues(`merchantInChargeInformation.${0}.name`);
      setValue(`merchantInChargeInformation.${1}.name`, getName);
      clearErrors(`merchantInChargeInformation.${1}.name`);
      const getEmail = getValues(`merchantInChargeInformation.${0}.email`);
      setValue(`merchantInChargeInformation.${1}.email`, getEmail);
      clearErrors(`merchantInChargeInformation.${1}.email`);
      const getPhone = getValues(`merchantInChargeInformation.${0}.phone`);
      setValue(`merchantInChargeInformation.${1}.phone`, getPhone);
      clearErrors(`merchantInChargeInformation.${1}.phone`);
    }
  };

  // 세금 담당자 체크박스
  const taxCheckBox = (e: any) => {
    setIsCheckedTax(e.target.checked);
    if (e.target.checked) {
      const getName = getValues(`merchantInChargeInformation.${1}.name`);
      setValue(`merchantInChargeInformation.${2}.name`, getName);
      clearErrors(`merchantInChargeInformation.${2}.name`);
      const getEmail = getValues(`merchantInChargeInformation.${1}.email`);
      setValue(`merchantInChargeInformation.${2}.email`, getEmail);
      clearErrors(`merchantInChargeInformation.${2}.email`);
      const getPhone = getValues(`merchantInChargeInformation.${1}.phone`);
      setValue(`merchantInChargeInformation.${2}.phone`, getPhone);
      clearErrors(`merchantInChargeInformation.${2}.phone`);
    }
  };

  // ===================================================================================================================
  // 파일 확장자 검사
  // ===================================================================================================================
  // 파일 확장자 검사
  const checkFileExtension = (fileName: string) => {
    const fileExtension = fileName.split(".").pop()?.toLowerCase();
    return ["jpg", "jpeg", "gif", "png", "pdf"].includes(fileExtension ?? "");
  };

  const fileValidation = useCallback(() => {
    const fileFields: { name: keyof FileUploadForm; label: string }[] = [
      { name: "businessLicense", label: "사업자등록증" },
      { name: "bankBookCopy", label: "통장사본" },
      { name: "sealCertificate", label: "대표자 인감증명서" },
      { name: "사용인감계", label: "사용인감계" },
      { name: "corporateRegistration", label: "등기사항전부증명서" },
      { name: "idCardFront", label: "신분증 앞면" },
      { name: "idCardBack", label: "신분증 뒷면" },
      { name: "gradeConfirmation", label: "영중소 등급확인서" },
      { name: "actualOwnerConfirmation", label: "실제소유자확인서" },
      { name: "corporateSealCertificate", label: "법인인감증명서" }
    ];

    let isError = false;

    fileFields.forEach(({ name, label }) => {
      const file = fileData[name];
      if (file && !checkFileExtension(file.name)) {
        setError(name, {
          type: "required",
          message: `${label} 첨부 파일 종류는 jpg, jpeg, gif, png, pdf 입니다.`
        });
        isError = true;
      }
    });

    return isError;
  }, [fileData, setError]);

  const [buttonDisable, setButtonDisable] = useState(true);

  useEffect(() => {
    const isError = fileValidation();
    setButtonDisable(isError);
  }, [fileData, fileValidation]);

  // ===================================================================================================================
  // 파일 업로드
  // ===================================================================================================================
  const 파일선택체크 = (
    inputName:
      | "businessLicense"
      | "bankBookCopy"
      | "sealCertificate"
      | "corporateRegistration"
      | "idCardFront"
      | "idCardBack"
      | "actualOwnerConfirmation"
      | "corporateSealCertificate"
      | "gradeConfirmation"
      | "사용인감계"
  ) => {
    const selectedFileName = getValues(inputName);
    if (selectedFileName === "") {
      return false;
    } else {
      return true;
    }
  };
  const 파일선택텍스트 = (
    inputName:
      | "businessLicense"
      | "bankBookCopy"
      | "sealCertificate"
      | "corporateRegistration"
      | "idCardFront"
      | "idCardBack"
      | "actualOwnerConfirmation"
      | "corporateSealCertificate"
      | "gradeConfirmation"
      | "사용인감계"
  ) => {
    if (파일선택체크(inputName)) {
      return "파일 수정";
    } else {
      return "파일 선택";
    }
  };
  const 사업자등록증 = (e: any) => {
    if (e.target.files.length < 1) {
      return;
    }
    const file = e.target.files[0];
    const { name } = file;
    setValue("businessLicense", name);
    setFileData((prev) => {
      return { ...prev, businessLicense: file };
    });
    if (errors.businessLicense) {
      clearErrors("businessLicense");
    }
  };
  const 통장사본 = (e: any) => {
    if (e.target.files < 1) {
      return;
    }
    const file = e.target.files[0];
    const { name } = file;
    setValue("bankBookCopy", name);
    setFileData((prev) => {
      return { ...prev, bankBookCopy: file };
    });
    if (errors.bankBookCopy) {
      clearErrors("bankBookCopy");
    }
  };
  const 인감증명서 = (e: any) => {
    if (e.target.files < 1) {
      return;
    }
    const file = e.target.files[0];
    const { name } = file;
    setValue("sealCertificate", name);
    setFileData((prev) => {
      return { ...prev, sealCertificate: file };
    });
    if (errors.sealCertificate) {
      clearErrors("sealCertificate");
    }
  };
  const 등기사항전부증명서 = (e: any) => {
    if (e.target.files.length < 1) {
      return;
    }
    const file = e.target.files[0];
    const { name } = file;
    setValue("corporateRegistration", name);
    setFileData((prev) => {
      return { ...prev, corporateRegistration: file };
    });
    if (errors.corporateRegistration) {
      clearErrors("corporateRegistration");
    }
  };
  const 신분증앞 = (e: any) => {
    if (e.target.files < 1) {
      return;
    }
    const file = e.target.files[0];
    const { name } = file;
    setValue("idCardFront", name);
    setFileData((prev) => {
      return { ...prev, idCardFront: file };
    });
    if (errors.idCardFront) {
      clearErrors("idCardFront");
    }
  };
  const 신분증뒤 = (e: any) => {
    if (e.target.files < 1) {
      return;
    }
    const file = e.target.files[0];
    const { name } = file;
    setValue("idCardBack", name);
    setFileData((prev) => {
      return { ...prev, idCardBack: file };
    });
    if (errors.idCardBack) {
      clearErrors("idCardBack");
    }
  };
  const 실제소유자확인서 = (e: any) => {
    if (e.target.files.length < 1) {
      return;
    }
    const file = e.target.files[0];
    const { name } = file;
    setValue("actualOwnerConfirmation", name);
    setFileData((prev) => {
      return { ...prev, actualOwnerConfirmation: file };
    });
    if (errors.actualOwnerConfirmation) {
      clearErrors("actualOwnerConfirmation");
    }
  };
  const 법인인감증명서 = (e: any) => {
    if (e.target.files.length < 1) {
      return;
    }
    const file = e.target.files[0];
    const { name } = file;
    setValue("corporateSealCertificate", name);
    setFileData((prev) => {
      return { ...prev, corporateSealCertificate: file };
    });
    if (errors.corporateSealCertificate) {
      clearErrors("corporateSealCertificate");
    }
  };
  const 등급확인서 = (e: any) => {
    if (e.target.files < 1) {
      return;
    }
    const file = e.target.files[0];
    const { name } = file;
    setValue("gradeConfirmation", name);
    if (errors.gradeConfirmation) {
      clearErrors("gradeConfirmation");
    }
  };
  const 사용인감계 = (e: any) => {
    if (e.target.files < 1) {
      return;
    }
    const file = e.target.files[0];
    const { name } = file;
    setValue("사용인감계", name);
    setFileData((prev) => {
      return { ...prev, 사용인감계: file };
    });
    if (errors.사용인감계) {
      clearErrors("사용인감계");
    }
  };

  return (
    <>
      {fileLoading && <Loading isLoading={fileLoading} />}
      <Paper
        sx={{
          width: "100%",
          py: 2,
          px: 4,
          border: "1px solid #F2F3F5",
          borderRadius: 3
        }}
      >
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          mb={2}
        >
          <Typography
            variant="subtitle2"
            sx={{ fontSize: "16px", fontWeight: "bold" }}
          >
            사업자 정보
          </Typography>
          <Button type="button" variant="outlined" onClick={onClickTempSave}>
            임시저장
          </Button>
        </Stack>

        <Divider sx={{ borderStyle: "dashed" }} />

        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          {/* 사업자 정보 -------------------------------------------------------------------------------------------*/}
          <Grid container spacing={4} py={3}>
            <Grid item xs={12} sm={3}>
              <Controller
                name="businessType"
                control={control}
                rules={{ required: "개인/법인 구분 확인은 필수값 입니다." }}
                render={({ field: { value, onChange } }) => (
                  <RadioGroup row value={value} onChange={onChange}>
                    <FormControlLabel
                      value="개인"
                      control={<Radio />}
                      label="개인"
                      sx={{ width: 100 }}
                    />
                    <FormControlLabel
                      value="법인"
                      control={<Radio />}
                      label="법인"
                      sx={{ width: 100 }}
                    />
                  </RadioGroup>
                )}
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <TextField
                id="mallName"
                type="text"
                label="가맹점명"
                required
                size="small"
                fullWidth
                {...register("mallName", {
                  required: "가맹점명은 필수값 입니다."
                })}
                helperText={
                  <HelperTextMessage>
                    {errors.mallName?.message}
                  </HelperTextMessage>
                }
                error={!!errors.mallName}
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <TextField
                id="businessName"
                placeholder="사업자등록증 상호명"
                label="사업자등록증 상호명"
                required
                size="small"
                {...register("businessName", {
                  required: "사업자등록증 상호명은 필수값 입니다."
                })}
                fullWidth
                helperText={
                  <HelperTextMessage>
                    {errors.businessName?.message}
                  </HelperTextMessage>
                }
                error={!!errors.businessName}
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <TextField
                id="registrationNumber"
                placeholder="등록번호 10자리를 입력해주세요."
                label="사업자등록번호"
                required
                size="small"
                {...register("registrationNumber", {
                  required: "사업자등록번호는 필수값 입니다.",
                  maxLength: {
                    value: 10,
                    message: "사업자등록번호는 10자리 입니다."
                  }
                })}
                fullWidth
                onInput={numberValidate}
                helperText={
                  <HelperTextMessage>
                    {errors.registrationNumber?.message}
                  </HelperTextMessage>
                }
                error={!!errors.registrationNumber}
              />
            </Grid>

            {currentBusinessType === "법인" ? (
              <>
                <Grid item xs={12} sm={3}>
                  <TextField
                    id="mallNameEn"
                    type="text"
                    label="사업장 영문 상호"
                    required
                    size="small"
                    {...register("mallNameEn", {
                      required: "사업장 영문 상호는 필수값 입니다."
                    })}
                    onInput={engValidate}
                    fullWidth
                    helperText={
                      <HelperTextMessage>
                        {errors.mallNameEn?.message}
                      </HelperTextMessage>
                    }
                    error={!!errors.mallNameEn}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    placeholder="등록번호 13자리를 입력해주세요."
                    label="법인등록번호"
                    required
                    size="small"
                    {...register("corporationNumber", {
                      required: "법인등록번호는 필수값 입니다.",
                      maxLength: {
                        value: 13,
                        message: "법인등록번호는 13자리 입니다."
                      }
                    })}
                    onInput={numberValidate}
                    fullWidth
                    helperText={
                      <HelperTextMessage>
                        {errors.corporationNumber?.message}
                      </HelperTextMessage>
                    }
                    error={!!errors.corporationNumber}
                  />
                </Grid>
                <Grid item xs={12} sm={4} />
              </>
            ) : (
              <></>
            )}
            {/* 가맹점, 본점 주소 */}
            <Grid item xs={12} sm={3} sx={{ position: "relative" }}>
              <InputLabel
                sx={{ position: "absolute", top: 14, fontSize: "14px" }}
              >
                가맹점 주소
              </InputLabel>
              <Stack direction={"row"} gap={3} alignItems={"center"}>
                <Button
                  type="button"
                  variant="contained"
                  sx={{
                    color: "white",
                    width: 120
                  }}
                  onClick={() => openPostCodeModal(0)}
                >
                  주소검색
                </Button>
                <Controller
                  name={"postCode"}
                  rules={{ required: "우편번호는 필수값 입니다." }}
                  control={control}
                  render={({ field: { value, onChange, ref } }) => (
                    <TextField
                      id={"postCode"}
                      size="small"
                      label="우편번호"
                      required
                      value={value}
                      onChange={onChange}
                      fullWidth
                      helperText={
                        <HelperTextMessage>
                          {errors.postCode?.message}
                        </HelperTextMessage>
                      }
                      onInput={numberValidate}
                      error={!!errors.postCode}
                      inputRef={ref}
                    />
                  )}
                ></Controller>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Controller
                name={"mallAddress"}
                control={control}
                rules={{
                  required: "주소는 필수값 입니다."
                }}
                render={({ field: { value, onChange } }) => (
                  <TextField
                    size="small"
                    label="주소"
                    required
                    value={value}
                    onChange={onChange}
                    fullWidth
                    helperText={
                      <HelperTextMessage>
                        {errors.mallAddress?.message}
                      </HelperTextMessage>
                    }
                    error={!!errors.mallAddress}
                  />
                )}
              ></Controller>
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                id="detailAddress"
                size="small"
                label="상세주소"
                required
                {...register("detailAddress", {
                  required: "상세주소는 필수값 입니다."
                })}
                fullWidth
                helperText={
                  <HelperTextMessage>
                    {errors.detailAddress?.message}
                  </HelperTextMessage>
                }
                error={!!errors.detailAddress}
              />
            </Grid>
            <Grid item xs={12} sm={3} />
            <Grid item xs={12} sm={3} sx={{ position: "relative" }}>
              <InputLabel
                sx={{ position: "absolute", top: 12, fontSize: "14px" }}
              >
                본점 주소
              </InputLabel>
              <Stack direction={"row"} gap={3} alignItems={"center"}>
                <Button
                  type="button"
                  variant="contained"
                  disabled={isCheckedAddress}
                  sx={{
                    color: "white",
                    width: 120
                  }}
                  onClick={() => openPostCodeModal(1)}
                >
                  주소검색
                </Button>
                <Controller
                  name={"headOfficePostCode"}
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      id="headOfficePostCode"
                      size="small"
                      label="우편번호"
                      fullWidth
                      value={isCheckedAddress ? watch("postCode") : value}
                      onChange={onChange}
                      disabled={isCheckedAddress}
                      onInput={numberValidate}
                    />
                  )}
                ></Controller>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Controller
                name={"headOfficeAddress"}
                control={control}
                render={({ field: { value, onChange } }) => (
                  <TextField
                    id="headOfficeAddress"
                    size="small"
                    label="주소"
                    fullWidth
                    value={isCheckedAddress ? watch("mallAddress") : value}
                    onChange={onChange}
                    disabled={isCheckedAddress}
                  />
                )}
              ></Controller>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Controller
                name={"headOfficeDetailAddress"}
                control={control}
                render={({ field: { value, onChange } }) => (
                  <TextField
                    id="headOfficeDetailAddress"
                    size="small"
                    label="상세주소"
                    fullWidth
                    value={isCheckedAddress ? watch("detailAddress") : value}
                    onChange={onChange}
                    disabled={isCheckedAddress}
                  />
                )}
              ></Controller>
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormControlLabel
                control={
                  <Checkbox checked={isCheckedAddress} onChange={checkBox} />
                }
                label="가맹점 주소와 동일"
                labelPlacement="end"
              />
            </Grid>
            <PostCode
              openPostCode={openPostCode}
              setOpenPostCode={setOpenPostCode}
              PostCodeHandler={postCodeHandler}
            />

            {/* 업종, 업태 */}
            <Grid item xs={12} sm={3} sx={{ position: "relative" }}>
              <InputLabel
                sx={{ position: "absolute", top: 12, fontSize: "14px" }}
              >
                업태&nbsp;
                <span style={{ fontSize: 14 }}>
                  (등록할 수 있는 업태의 개수는 최대 5개입니다.)
                </span>
              </InputLabel>
              <TextField
                label="업태"
                fullWidth
                size="small"
                // {...register("businessConditions")}
                value={conditionValue}
                onChange={(e) => setConditionValue(e.target.value)}
                onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    handleAddBusinessCondition();
                  }
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button onClick={handleAddBusinessCondition}>추가</Button>
                    </InputAdornment>
                  )
                }}
                error={!!errors.businessConditions}
                helperText={
                  <HelperTextMessage>
                    {errors.businessConditions?.message}
                  </HelperTextMessage>
                }
              />
            </Grid>
            <Grid item xs={12} sm={9} container alignItems={"center"} gap={2}>
              {businessConditionsFields.map((item, index) => (
                <Chip
                  key={item.id}
                  label={item.name}
                  onDelete={() => handleDeleteBusinessCondition(index)}
                  sx={{ borderRadius: 1 }}
                />
              ))}
            </Grid>

            <Grid item xs={12} sm={3} sx={{ position: "relative" }}>
              <InputLabel
                htmlFor="merchantCategory"
                sx={{ position: "absolute", top: 12, fontSize: "14px" }}
              >
                업종&nbsp;
                <span style={{ fontSize: 14 }}>
                  (등록할 수 있는 업종의 개수는 최대 5개입니다.)
                </span>
              </InputLabel>

              <TextField
                label="업종"
                fullWidth
                size="small"
                value={categoryValue}
                onChange={(e) => setCategoryValue(e.target.value)}
                onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    handleAddMerchantCategory();
                  }
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button onClick={handleAddMerchantCategory}>추가</Button>
                    </InputAdornment>
                  )
                }}
                error={!!errors.merchantCategory}
                helperText={
                  <HelperTextMessage>
                    {errors.merchantCategory?.message}
                  </HelperTextMessage>
                }
              />
            </Grid>

            <Grid item xs={12} sm={9} container alignItems={"center"} gap={2}>
              {merchantCategoryFields.map((item, index) => (
                <Chip
                  key={item.id}
                  label={item.name}
                  onDelete={() => handleDeleteMerchantCategory(index)}
                  sx={{ borderRadius: 1 }}
                />
              ))}
            </Grid>

            <Grid item xs={12} sm={3}>
              <TextField
                id="phone"
                label="전화번호"
                required
                size="small"
                fullWidth
                {...register("phone", {
                  required: "전화번호는 필수값 입니다.",
                  maxLength: {
                    value: 12,
                    message: "12자 이하로 입력해 주세요."
                  }
                })}
                onInput={numberValidate}
                placeholder="&#39;-&#39; 없이 숫자만 입력해 주세요."
                helperText={
                  <HelperTextMessage>
                    {errors?.phone?.message}
                  </HelperTextMessage>
                }
                error={!!errors.phone}
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <TextField
                id="fax"
                label="팩스번호"
                size="small"
                fullWidth
                {...register("fax", {
                  maxLength: {
                    value: 12,
                    message: "12자 이하로 입력해 주세요."
                  }
                })}
                onInput={numberValidate}
                placeholder="&#39;-&#39; 없이 숫자만 입력해 주세요."
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <TextField
                id="mailOrderSalesNumber"
                label="통신판매번호"
                required
                size="small"
                fullWidth
                {...register("mailOrderSalesNumber", {
                  required: "통신판매번호는 필수값 입니다."
                })}
                onInput={numberValidate}
                helperText={
                  <HelperTextMessage>
                    {errors?.mailOrderSalesNumber?.message}
                  </HelperTextMessage>
                }
                error={!!errors.mailOrderSalesNumber}
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <TextField
                id="mallUrl"
                label="홈페이지 주소"
                required
                size="small"
                fullWidth
                {...register("mallUrl", {
                  required: "홈페이지 주소는 필수값 입니다."
                })}
                helperText={
                  <HelperTextMessage>
                    {errors?.mallUrl?.message}
                  </HelperTextMessage>
                }
                error={!!errors.mallUrl}
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <TextField
                id="mallIp"
                label="가맹점 IP"
                required
                size="small"
                fullWidth
                helperText={
                  <HelperTextMessage>
                    {errors.mallIp?.message}
                  </HelperTextMessage>
                }
                {...register("mallIp", {
                  required: "가맹점 IP는 필수값 입니다.",
                  pattern: {
                    value:
                      /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
                    message: "형식에 맞는 IP주소를 입력해 주세요."
                  }
                })}
                error={!!errors.mallIp}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                id="introductionCompany"
                label="소개업체 (호스팅사)"
                size="small"
                fullWidth
                {...register("introductionCompany")}
              />
            </Grid>

            <Grid item xs={12} sm={3} sx={{ position: "relative" }}>
              <Controller
                name="testId"
                control={control}
                rules={{
                  required: "테스트용 ID 는 필수값 입니다."
                }}
                render={({ field: { value, onChange, ref } }) => (
                  <TextField
                    id="testId"
                    label="테스트용 ID (카드사 심사용)"
                    required
                    size="small"
                    fullWidth
                    value={value}
                    onChange={onChange}
                    helperText={
                      <HelperTextMessage>
                        {errors.testId?.message}
                      </HelperTextMessage>
                    }
                    error={!!errors.testId}
                    inputRef={ref}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Tooltip
                            title={
                              <Typography variant="subtitle2" fontSize={11}>
                                ID는 연동 신청하는 쇼핑몰에 로그인 가능한
                                테스트용 아이디 입니다.
                              </Typography>
                            }
                            placement="right"
                            arrow
                          >
                            <IconButton type="button">
                              <ErrorOutline />
                            </IconButton>
                          </Tooltip>
                        </InputAdornment>
                      )
                    }}
                  />
                )}
              ></Controller>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isCheckedTestId}
                    onChange={handleCheckboxChange}
                  />
                }
                label="비회원 결제가능"
                labelPlacement="end"
                sx={{ position: "absolute", bottom: -35, right: -16 }}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Controller
                name="testPassword"
                control={control}
                rules={{
                  required: "테스트용 비밀번호는 필수값 입니다."
                }}
                render={({ field: { value, onChange } }) => (
                  <TextField
                    id="testPassword"
                    label="테스트용 비밀번호 (카드사 심사용)"
                    required
                    size="small"
                    fullWidth
                    value={value}
                    onChange={onChange}
                    helperText={
                      <HelperTextMessage>
                        {errors.testPassword?.message}
                      </HelperTextMessage>
                    }
                    error={!!errors.testPassword}
                    ref={register("testPassword").ref}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Tooltip
                            title={
                              <Typography variant="subtitle2" fontSize={11}>
                                비밀번호는 연동 신청하는 쇼핑몰에 로그인 가능한
                                아이디의 비밀번호 입니다.
                              </Typography>
                            }
                            placement="right"
                            arrow
                          >
                            <IconButton type="button">
                              <ErrorOutline />
                            </IconButton>
                          </Tooltip>
                        </InputAdornment>
                      )
                    }}
                  />
                )}
              ></Controller>
            </Grid>
          </Grid>
          {/* 대표자 정보 -------------------------------------------------------------------------------------------*/}
          <Typography
            variant="subtitle2"
            sx={{ fontSize: "16px", fontWeight: "bold", py: 2 }}
          >
            대표자 정보
          </Typography>
          <Divider sx={{ borderStyle: "dashed" }} />
          <Typography sx={{ fontSize: "14px", mt: 3 }}>
            * 공동 대표인 경우 공동 대표 2인 전부 필수 입력
          </Typography>
          {fields.map((item, index) => (
            <Grid container spacing={4} py={3} key={item.id}>
              <Grid item xs={12} sm={3}>
                <TextField
                  id={`merchantOwnerInformation.${index}.name`}
                  label="대표자 한글이름"
                  required
                  size="small"
                  fullWidth
                  {...register(`merchantOwnerInformation.${index}.name`, {
                    required: "대표자명은 필수값 입니다."
                  })}
                  helperText={
                    <HelperTextMessage>
                      {errors?.merchantOwnerInformation?.[index]?.name?.message}
                    </HelperTextMessage>
                  }
                  error={!!errors.merchantOwnerInformation?.[index]?.name}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  id="nameEn"
                  label="대표자 영문이름"
                  placeholder="ex) HONG GIL DONG"
                  required
                  size="small"
                  fullWidth
                  {...register(`merchantOwnerInformation.${index}.nameEn`, {
                    required: "대표자 영문이름은 필수값 입니다."
                  })}
                  onInput={engValidate}
                  helperText={
                    <HelperTextMessage>
                      {
                        errors?.merchantOwnerInformation?.[index]?.nameEn
                          ?.message
                      }
                    </HelperTextMessage>
                  }
                  error={!!errors.merchantOwnerInformation?.[index]?.nameEn}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <Button
                  type="button"
                  variant="contained"
                  onClick={() => {
                    append({
                      name: "",
                      birth: "",
                      nationality: "대한민국",
                      address: "",
                      phone: "",
                      postCode: "",
                      detailAddress: ""
                    });
                  }}
                  sx={{
                    color: "white",
                    mr: 2
                  }}
                >
                  공동 대표자 추가
                </Button>
                <Button
                  type="button"
                  variant="outlined"
                  onClick={() => remove(index)}
                  disabled={fields.length < 2}
                >
                  양식삭제
                </Button>
              </Grid>
              <Grid item xs={12} sm={3} />

              <Grid item xs={12} sm={3}>
                <TextField
                  id={`merchantOwnerInformation.${index}.birth`}
                  label="생년월일"
                  required
                  size="small"
                  placeholder="생년월일 8자리를 입력해 주세요."
                  fullWidth
                  {...register(`merchantOwnerInformation.${index}.birth`, {
                    required: "생년월일은 필수값 입니다.",
                    pattern: {
                      value:
                        /^(19[0-9][0-9]|20\d{2})(0[1-9]|1[0-2])(0[1-9]|[1-2][0-9]|3[0-1])$/,
                      message: "정확한 생년월일을 입력해 주세요."
                    },
                    maxLength: {
                      value: 8,
                      message: "생년월일 8자리를 입력해 주세요."
                    },
                    minLength: {
                      value: 8,
                      message: "생년월일 8자리를 입력해 주세요."
                    }
                  })}
                  onInput={numberValidate}
                  helperText={
                    <HelperTextMessage>
                      {
                        errors?.merchantOwnerInformation?.[index]?.birth
                          ?.message
                      }
                    </HelperTextMessage>
                  }
                  error={!!errors.merchantOwnerInformation?.[index]?.birth}
                />
              </Grid>

              <Grid item xs={12} sm={3}>
                {/* <Controller
                  name={`merchantOwnerInformation.${index}.nationality`}
                  control={control}
                  rules={{
                    required: "대표자 국적은 필수값 입니다."
                  }}
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      label="대표자 국적"
                      size="small"
                      required
                      select
                      value={value}
                      onChange={onChange}
                      fullWidth
                    >
                      {CountryList.map(({ value, text }) => (
                        <MenuItem key={value} value={text}>
                          {text}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                ></Controller> */}

                {/* 대표자 국적 자동 완성 기능 테스트 */}
                <Controller
                  name={`merchantOwnerInformation.${index}.nationality`}
                  control={control}
                  rules={{
                    required: "대표자 국적은 필수값 입니다."
                  }}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      id={`merchantOwnerInformation.${index}.nationality`}
                      getOptionLabel={(option) => option.text}
                      value={
                        CountryList.find((option) => option.text === value) ||
                        null
                      }
                      options={CountryList}
                      onChange={(event, newValue) => {
                        onChange(newValue ? newValue.text : "");
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="대표자 국적"
                          size="small"
                          required
                          fullWidth
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  id={`merchantOwnerInformation.${index}.phone`}
                  label="대표자 연락처"
                  required
                  size="small"
                  fullWidth
                  {...register(`merchantOwnerInformation.${index}.phone`, {
                    required: "대표자 연락처는 필수값 입니다.",
                    maxLength: {
                      value: 12,
                      message: "전화번호는 12자리 이하로 입력 해주세요."
                    }
                  })}
                  onInput={numberValidate}
                  placeholder="&#39;-&#39; 없이 숫자만 입력해 주세요."
                  helperText={
                    <HelperTextMessage>
                      {
                        errors?.merchantOwnerInformation?.[index]?.phone
                          ?.message
                      }
                    </HelperTextMessage>
                  }
                  error={!!errors.merchantOwnerInformation?.[index]?.phone}
                />
              </Grid>
              <Grid item xs={12} sm={3} />

              <Grid item xs={12} sm={3}>
                <Stack direction={"row"} gap={3} alignItems={"center"}>
                  <Button
                    type="button"
                    variant="contained"
                    sx={{
                      color: "white",
                      width: 120
                    }}
                    onClick={() => openOwnerPostCodeModal(index)}
                  >
                    주소검색
                  </Button>
                  <Controller
                    name={`merchantOwnerInformation.${index}.postCode`}
                    control={control}
                    rules={{
                      required: "우편번호는 필수값 입니다.",
                      pattern: {
                        value: /^[0-9]+$/,
                        message: "숫자만 입력해 주세요."
                      }
                    }}
                    render={({ field: { value, onChange } }) => (
                      <TextField
                        size="small"
                        fullWidth
                        label="우편번호"
                        required
                        value={value}
                        onChange={onChange}
                        onInput={numberValidate}
                        helperText={
                          <HelperTextMessage>
                            {
                              errors?.merchantOwnerInformation?.[index]
                                ?.postCode?.message
                            }
                          </HelperTextMessage>
                        }
                        error={
                          !!errors.merchantOwnerInformation?.[index]?.postCode
                        }
                      />
                    )}
                  ></Controller>
                </Stack>
              </Grid>
              <PostCode
                openPostCode={openOwnerPostCode}
                setOpenPostCode={setOpenOwnerPostCode}
                PostCodeHandler={ownerAddressHandler}
              />

              <Grid item xs={12} sm={3}>
                <Controller
                  name={`merchantOwnerInformation.${index}.address`}
                  control={control}
                  rules={{
                    required: "주소는 필수값 입니다."
                  }}
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      size="small"
                      fullWidth
                      label="주소"
                      required
                      value={value}
                      onChange={onChange}
                      helperText={
                        <HelperTextMessage>
                          {
                            errors?.merchantOwnerInformation?.[index]?.address
                              ?.message
                          }
                        </HelperTextMessage>
                      }
                      error={
                        !!errors.merchantOwnerInformation?.[index]?.address
                      }
                    />
                  )}
                ></Controller>
              </Grid>

              <Grid item xs={12} sm={3}>
                <TextField
                  id="detailAddress"
                  fullWidth
                  size="small"
                  label="상세주소"
                  required
                  {...register(
                    `merchantOwnerInformation.${index}.detailAddress`,
                    {
                      required: "상세주소는 필수값 입니다."
                    }
                  )}
                  helperText={
                    <HelperTextMessage>
                      {
                        errors?.merchantOwnerInformation?.[index]?.detailAddress
                          ?.message
                      }
                    </HelperTextMessage>
                  }
                  error={
                    !!errors.merchantOwnerInformation?.[index]?.detailAddress
                  }
                />
              </Grid>

              <Divider sx={{ borderStyle: "dashed" }} />
            </Grid>
          ))}
          {/* 은행 정보 --------------------------------------------------------------------------------------------*/}
          <Typography
            variant="subtitle2"
            sx={{ fontSize: "16px", fontWeight: "bold", py: 2 }}
          >
            은행 정보
          </Typography>
          <Divider sx={{ borderStyle: "dashed" }} />
          <Typography sx={{ fontSize: "14px", mt: 3 }}>
            * 정산 은행의 경우 개인사업자는 대표자 명의, 법인사업자는 법인
            명의의 계좌만 이용 가능합니다. <br />* 예금주의 경우, 개인사업자는
            대표자 또는 상호명, 법인사업자는 상호명과 일치해야 합니다.
          </Typography>
          <Grid container spacing={4} py={3}>
            <Grid item xs={12} sm={3}>
              <Controller
                name="bankName"
                control={control}
                rules={{
                  required: "은행명은 필수값 입니다."
                }}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    id="bankName"
                    options={bankNameList?.content || []}
                    getOptionLabel={(option) => option.name}
                    value={
                      bankNameList?.content.find(
                        (option) => option.name === value
                      ) || null
                    }
                    onChange={(event, newValue) => {
                      onChange(newValue ? newValue.name : "");
                      setSelectedBank(
                        newValue ? newValue : { name: "", code: "" }
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        label="은행명"
                        required
                        {...params}
                        fullWidth
                        error={!!errors.bankName}
                        helperText={
                          <HelperTextMessage>
                            {errors.bankName?.message}
                          </HelperTextMessage>
                        }
                        size="small"
                      />
                    )}
                  />
                )}
              ></Controller>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Controller
                name="accountCode"
                control={control}
                rules={{
                  required: "은행 계좌번호는 필수값 입니다."
                }}
                render={({ field: { value, onChange } }) => (
                  <TextField
                    id="accountCode"
                    label="은행 계좌번호"
                    required
                    fullWidth
                    size="small"
                    value={value}
                    onChange={onChange}
                    helperText={
                      <HelperTextMessage>
                        {errors.accountCode?.message}
                      </HelperTextMessage>
                    }
                    onInput={numberValidate}
                    error={!!errors.accountCode}
                  />
                )}
              ></Controller>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Controller
                name="accountHolder"
                control={control}
                rules={{
                  required: "계좌 소유주는 필수값 입니다."
                }}
                render={({ field: { value, onChange } }) => (
                  <TextField
                    id="accountHolder"
                    label="소유주 이름"
                    required
                    fullWidth
                    size="small"
                    value={value}
                    onChange={onChange}
                    helperText={
                      <HelperTextMessage>
                        {errors.accountHolder?.message}
                      </HelperTextMessage>
                    }
                    error={!!errors.accountHolder}
                  />
                )}
              ></Controller>
            </Grid>
            <Grid item xs={12} sm={3} />
          </Grid>
          {/* 담당자 정보 -------------------------------------------------------------------------------------------*/}
          <Typography
            variant="subtitle2"
            sx={{ fontSize: "16px", fontWeight: "bold", py: 2 }}
          >
            담당자 정보
          </Typography>
          <Divider sx={{ borderStyle: "dashed" }} />
          {/* 계약 담당자 */}
          <Grid container spacing={4} py={3}>
            <Grid item xs={12} sm={3}>
              <TextField
                id="name"
                label="계약 담당자명"
                size="small"
                required
                fullWidth
                {...register(`merchantInChargeInformation.${0}.name`, {
                  required: "계약 담당자명은 필수값 입니다."
                })}
                helperText={
                  <HelperTextMessage>
                    {errors?.merchantInChargeInformation?.[0]?.name?.message}
                  </HelperTextMessage>
                }
                error={!!errors?.merchantInChargeInformation?.[0]?.name}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                id="email"
                label="계약 담당자 이메일"
                size="small"
                required
                fullWidth
                {...register(`merchantInChargeInformation.${0}.email`, {
                  required: "계약 담당자 이메일은 필수값 입니다.",
                  pattern: {
                    value:
                      /^([0-9a-zA-Z_.-]+)@([0-9a-zA-Z_-]+)(\.[0-9a-zA-Z_-]+){1,2}$/,
                    message: "형식에 맞지 않는 이메일 주소입니다."
                  }
                })}
                helperText={
                  <HelperTextMessage>
                    {errors?.merchantInChargeInformation?.[0]?.email?.message}
                  </HelperTextMessage>
                }
                error={!!errors?.merchantInChargeInformation?.[0]?.email}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                id="phone"
                label="계약 담당자 연락처"
                size="small"
                required
                fullWidth
                {...register(`merchantInChargeInformation.${0}.phone`, {
                  required: "계약 담당자 연락처는 필수값 입니다.",
                  maxLength: {
                    value: 12,
                    message: "전화번호는 12자리 이하로 입력 해주세요."
                  }
                })}
                onInput={numberValidate}
                placeholder="&#39;-&#39; 없이 숫자만 입력해 주세요."
                helperText={
                  <HelperTextMessage>
                    {errors?.merchantInChargeInformation?.[0]?.phone?.message}
                  </HelperTextMessage>
                }
                error={!!errors?.merchantInChargeInformation?.[0]?.phone}
              />
            </Grid>
            <Grid item xs={12} sm={3} />

            {/* 정산 담당자 */}
            <Grid item xs={12} sm={3}>
              <Controller
                name={`merchantInChargeInformation.${1}.name`}
                control={control}
                rules={{
                  required: "정산 담당자명은 필수값 입니다."
                }}
                render={({ field: { value, onChange } }) => (
                  <TextField
                    id="name"
                    label="정산 담당자명"
                    size="small"
                    required
                    fullWidth
                    value={
                      isChecked
                        ? watch(`merchantInChargeInformation.${0}.name`)
                        : value
                    }
                    helperText={
                      <HelperTextMessage>
                        {
                          errors?.merchantInChargeInformation?.[1]?.name
                            ?.message
                        }
                      </HelperTextMessage>
                    }
                    onChange={onChange}
                    error={!!errors?.merchantInChargeInformation?.[1]?.name}
                    disabled={isChecked}
                  />
                )}
              ></Controller>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Controller
                name={`merchantInChargeInformation.${1}.email`}
                control={control}
                rules={{
                  required: "정산 담당자 이메일은 필수값 입니다.",
                  pattern: {
                    value:
                      /^([0-9a-zA-Z_.-]+)@([0-9a-zA-Z_-]+)(\.[0-9a-zA-Z_-]+){1,2}$/,
                    message: "형식에 맞지 않는 이메일 주소입니다."
                  }
                }}
                render={({ field: { value, onChange } }) => (
                  <TextField
                    id={`merchantInChargeInformation.${1}.email`}
                    label="정산 담당자 이메일"
                    size="small"
                    required
                    fullWidth
                    onChange={onChange}
                    value={
                      isChecked
                        ? watch(`merchantInChargeInformation.${0}.email`)
                        : value
                    }
                    helperText={
                      <HelperTextMessage>
                        {
                          errors?.merchantInChargeInformation?.[1]?.email
                            ?.message
                        }
                      </HelperTextMessage>
                    }
                    error={!!errors?.merchantInChargeInformation?.[1]?.email}
                    disabled={isChecked}
                  />
                )}
              ></Controller>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Controller
                name={`merchantInChargeInformation.${1}.phone`}
                control={control}
                rules={{
                  required: "정산 담당자 전화번호는 필수값 입니다.",
                  maxLength: {
                    value: 12,
                    message: "전화번호는 12자리 이하로 입력 해주세요."
                  }
                }}
                render={({ field: { value, onChange } }) => (
                  <TextField
                    id={`merchantInChargeInformation.${1}.phone`}
                    label="정산 담당자 전화번호"
                    size="small"
                    value={
                      isChecked
                        ? watch(`merchantInChargeInformation.${0}.phone`)
                        : value
                    }
                    onChange={onChange}
                    required
                    fullWidth
                    helperText={
                      <HelperTextMessage>
                        {
                          errors?.merchantInChargeInformation?.[1]?.phone
                            ?.message
                        }
                      </HelperTextMessage>
                    }
                    error={!!errors?.merchantInChargeInformation?.[1]?.phone}
                    disabled={isChecked}
                    onInput={numberValidate}
                  />
                )}
              ></Controller>
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormControlLabel
                control={
                  <Checkbox checked={isChecked} onChange={managerCheckBox} />
                }
                label="계약 담당자 정보와 동일"
                labelPlacement="end"
              />
            </Grid>

            {/* 세금 계산 담당자 */}
            <Grid item xs={12} sm={3}>
              <Controller
                name={`merchantInChargeInformation.${2}.name`}
                control={control}
                rules={{
                  required: "세금 계산 담당자명은 필수값 입니다."
                }}
                render={({ field: { value, onChange } }) => (
                  <TextField
                    id="name"
                    label="세금 담당자명"
                    size="small"
                    value={
                      isCheckedTax
                        ? watch(`merchantInChargeInformation.${1}.name`)
                        : value
                    }
                    required
                    fullWidth
                    onChange={onChange}
                    helperText={
                      <HelperTextMessage>
                        {
                          errors?.merchantInChargeInformation?.[2]?.name
                            ?.message
                        }
                      </HelperTextMessage>
                    }
                    error={!!errors?.merchantInChargeInformation?.[2]?.name}
                    disabled={isCheckedTax}
                  />
                )}
              ></Controller>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Controller
                name={`merchantInChargeInformation.${2}.email`}
                control={control}
                rules={{
                  required: "세금 담당자 이메일은 필수값 입니다.",
                  pattern: {
                    value:
                      /^([0-9a-zA-Z_.-]+)@([0-9a-zA-Z_-]+)(\.[0-9a-zA-Z_-]+){1,2}$/,
                    message: "형식에 맞지 않는 이메일 주소입니다."
                  }
                }}
                render={({ field: { value, onChange } }) => (
                  <TextField
                    id={`merchantInChargeInformation.${2}.email`}
                    label="세금 담당자 이메일"
                    size="small"
                    value={
                      isCheckedTax
                        ? watch(`merchantInChargeInformation.${1}.email`)
                        : value
                    }
                    onChange={onChange}
                    required
                    fullWidth
                    helperText={
                      <HelperTextMessage>
                        {
                          errors?.merchantInChargeInformation?.[2]?.email
                            ?.message
                        }
                      </HelperTextMessage>
                    }
                    disabled={isCheckedTax}
                    error={!!errors?.merchantInChargeInformation?.[2]?.email}
                  />
                )}
              ></Controller>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Controller
                name={`merchantInChargeInformation.${2}.phone`}
                control={control}
                rules={{
                  required: "세금 담당자 전화번호는 필수값 입니다.",
                  maxLength: {
                    value: 12,
                    message: "전화번호는 12자리 이하로 입력 해주세요."
                  }
                }}
                render={({ field: { value, onChange } }) => (
                  <TextField
                    id={`merchantInChargeInformation.${2}.phone`}
                    label="세금 담당자 연락처"
                    size="small"
                    required
                    fullWidth
                    value={
                      isCheckedTax
                        ? watch(`merchantInChargeInformation.${1}.phone`)
                        : value
                    }
                    onChange={onChange}
                    onInput={numberValidate}
                    placeholder="&#39;-&#39; 없이 숫자만 입력해 주세요."
                    helperText={
                      <HelperTextMessage>
                        {
                          errors?.merchantInChargeInformation?.[2]?.phone
                            ?.message
                        }
                      </HelperTextMessage>
                    }
                    error={!!errors?.merchantInChargeInformation?.[2]?.phone}
                    disabled={isCheckedTax}
                  />
                )}
              ></Controller>
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormControlLabel
                control={
                  <Checkbox checked={isCheckedTax} onChange={taxCheckBox} />
                }
                label="정산 담당자 정보와 동일"
                labelPlacement="end"
              />
            </Grid>
          </Grid>
          {/* 파일 등록 정보 -----------------------------------------------------------------------------------------*/}
          <Typography
            variant="subtitle2"
            sx={{ fontSize: "16px", fontWeight: "bold", py: 2 }}
          >
            파일 정보
          </Typography>
          <Divider sx={{ borderStyle: "dashed" }} />
          <Typography variant="body2" sx={{ mt: 3 }}>
            * 제출 서류의 첨부파일 최대 용량은 10MB 입니다. <br />* 제출 서류의
            첨부 파일 종류는 jpg, jpeg, gif, png, pdf 입니다.
          </Typography>
          <Typography variant="body2" sx={{ mb: 1.5 }} color={"error"}>
            * 파일은 임시저장할 수 없습니다.
          </Typography>
          {/* 개인 사업자 파일 등록 --------------------------------------------------------------------------------- */}
          {currentBusinessType === "개인" ? (
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <InputLabel>사업자등록증</InputLabel>
                <Stack direction={"row"} alignItems="center">
                  <TextField
                    size="small"
                    sx={{ width: 350 }}
                    error={!!errors.businessLicense}
                    disabled
                    {...register("businessLicense", {
                      required: "사업자등록증은 필수 파일 입니다."
                    })}
                  />
                  <Button
                    component="label"
                    sx={{
                      mx: 2,
                      background: "#eee",
                      color: "black",
                      p: 1
                    }}
                  >
                    <input
                      type="file"
                      accept="image/*, .pdf"
                      hidden
                      onChange={사업자등록증}
                    />
                    {파일선택텍스트("businessLicense")}
                  </Button>
                  {errors.businessLicense && (
                    <Typography variant="caption" color="error">
                      {errors.businessLicense.message}
                    </Typography>
                  )}
                </Stack>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Stack direction={"row"} alignItems={"center"} gap={1}>
                  <InputLabel>통장 사본</InputLabel>
                  <Typography sx={{ fontSize: "12px", color: "gray" }}>
                    (대표자 명의)
                  </Typography>
                </Stack>
                <TextField
                  size="small"
                  disabled
                  sx={{ width: 350 }}
                  error={!!errors.bankBookCopy}
                  {...register("bankBookCopy", {
                    required: "통장 사본은 필수 파일 입니다."
                  })}
                />
                <Button
                  component="label"
                  sx={{ mx: 2, background: "#eee", color: "black", p: 1 }}
                >
                  <input
                    type="file"
                    accept="image/*, .pdf"
                    hidden
                    onChange={통장사본}
                  />
                  {파일선택텍스트("bankBookCopy")}
                </Button>
                {errors.bankBookCopy && (
                  <Typography variant="caption" color="error">
                    {errors.bankBookCopy.message}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} sm={12}>
                <Stack direction={"row"} gap={1} alignItems={"center"}>
                  <InputLabel>대표자 인감증명서</InputLabel>
                  <Typography sx={{ fontSize: "12px", color: "gray" }}>
                    (원본 1부)
                  </Typography>
                  <Typography sx={{ fontSize: "12px", color: "error.main" }}>
                    * 서류는 제출기한 기준 최근 3개월 이내 발급 받은 것
                  </Typography>
                </Stack>
                <TextField
                  size="small"
                  disabled
                  sx={{ width: 350 }}
                  error={!!errors.sealCertificate}
                  {...register("sealCertificate", {
                    required: "대표자 인감증명서는 필수 파일 입니다."
                  })}
                />
                <Button
                  component="label"
                  sx={{
                    mx: 2,
                    background: "#eee",
                    color: "black",
                    p: 1
                  }}
                >
                  <input
                    type="file"
                    accept="image/*, .pdf"
                    hidden
                    onChange={인감증명서}
                  />
                  {파일선택텍스트("sealCertificate")}
                </Button>
                {errors.sealCertificate && (
                  <Typography variant="caption" color="error">
                    {errors.sealCertificate.message}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} sm={12}>
                <Stack direction={"row"} gap={1} alignItems={"center"}>
                  <InputLabel>대표자 신분증 (앞면)</InputLabel>
                  <Typography sx={{ fontSize: "12px", color: "gray" }}>
                    (앞면 사본 1부)
                  </Typography>
                </Stack>
                <TextField
                  size="small"
                  disabled
                  sx={{ width: 350 }}
                  error={!!errors.idCardFront}
                  {...register("idCardFront", {
                    required: "대표자 신분증은 필수 파일 입니다."
                  })}
                />
                <Button
                  component="label"
                  sx={{ background: "#eee", color: "black", p: 1, mx: 2 }}
                >
                  <input
                    type="file"
                    accept="image/*, .pdf"
                    hidden
                    onChange={신분증앞}
                  />
                  {파일선택텍스트("idCardFront")}
                </Button>
                {errors.idCardFront && (
                  <Typography variant="caption" color="error">
                    {errors.idCardFront.message}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} sm={12}>
                <Stack direction={"row"} gap={1} alignItems={"center"}>
                  <InputLabel>대표자 신분증 (뒷면)</InputLabel>
                  <Typography sx={{ fontSize: "12px", color: "gray" }}>
                    (뒷면 사본 1부)
                  </Typography>
                </Stack>
                <TextField
                  size="small"
                  disabled
                  sx={{ width: 350 }}
                  error={!!errors.idCardBack}
                  {...register("idCardBack", {
                    required: "대표자 신분증은 필수 파일 입니다."
                  })}
                />
                <Button
                  component="label"
                  sx={{ background: "#eee", color: "black", p: 1, mx: 2 }}
                >
                  <input
                    type="file"
                    accept="image/*, .pdf"
                    hidden
                    onChange={신분증뒤}
                  />
                  {파일선택텍스트("idCardBack")}
                </Button>
                {errors.idCardBack && (
                  <Typography variant="caption" color="error">
                    {errors.idCardBack.message}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} sm={12}>
                <InputLabel>영중소 등급확인서</InputLabel>
                <TextField
                  size="small"
                  sx={{ width: 350 }}
                  disabled
                  error={!!errors.gradeConfirmation}
                  {...register("gradeConfirmation")}
                />
                <Button
                  component="label"
                  sx={{ ml: 2, background: "#eee", color: "black", p: 1 }}
                >
                  <input
                    type="file"
                    accept="image/*, .pdf"
                    hidden
                    onChange={등급확인서}
                  />
                  {파일선택텍스트("gradeConfirmation")}
                </Button>
                {errors.gradeConfirmation && (
                  <Typography variant="caption" color="error">
                    {errors.gradeConfirmation.message}
                  </Typography>
                )}
              </Grid>
            </Grid>
          ) : (
            <>
              {/* 법인 사업자 파일 등록 ----------------------------------------------------------------------------- */}
              <Grid container spacing={3} py={3}>
                <Grid item xs={12} sm={12}>
                  <InputLabel>사업자등록증</InputLabel>
                  <Stack direction={"row"} alignItems="center">
                    <TextField
                      size="small"
                      sx={{ width: 350 }}
                      error={!!errors.businessLicense}
                      disabled
                      {...register("businessLicense", {
                        required: "사업자등록증은 필수 파일 입니다."
                      })}
                    />
                    <Button
                      component="label"
                      sx={{
                        mx: 2,
                        background: "#eee",
                        color: "black",
                        p: 1
                      }}
                    >
                      <input
                        type="file"
                        accept="image/*, .pdf"
                        hidden
                        onChange={사업자등록증}
                      />
                      {파일선택텍스트("businessLicense")}
                    </Button>
                    {errors.businessLicense && (
                      <Typography variant="caption" color="error">
                        {errors.businessLicense.message}
                      </Typography>
                    )}
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Stack direction={"row"} alignItems={"center"} gap={1}>
                    <InputLabel>통장 사본</InputLabel>
                    <Typography sx={{ fontSize: "12px", color: "gray" }}>
                      (법인 명의)
                    </Typography>
                  </Stack>
                  <TextField
                    size="small"
                    disabled
                    sx={{ width: 350 }}
                    error={!!errors.bankBookCopy}
                    {...register("bankBookCopy", {
                      required: "통장 사본은 필수 파일 입니다."
                    })}
                  />
                  <Button
                    component="label"
                    sx={{
                      mx: 2,
                      background: "#eee",
                      color: "black",
                      p: 1
                    }}
                  >
                    <input
                      type="file"
                      accept="image/*, .pdf"
                      hidden
                      onChange={통장사본}
                    />
                    {파일선택텍스트("bankBookCopy")}
                  </Button>
                  {errors.bankBookCopy && (
                    <Typography variant="caption" color="error">
                      {errors.bankBookCopy.message}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} sm={12}>
                  <InputLabel>법인인감증명서</InputLabel>
                  <Stack direction={"row"} gap={2} alignItems={"center"}>
                    <TextField
                      size="small"
                      sx={{ width: 350 }}
                      disabled
                      error={!!errors.corporateSealCertificate}
                      {...register("corporateSealCertificate", {
                        required: "법인인감증명서는 필수 파일 입니다."
                      })}
                    />
                    <Button
                      component="label"
                      sx={{
                        background: "#eee",
                        color: "black",
                        p: 1
                      }}
                    >
                      <input
                        type="file"
                        accept="image/*, .pdf"
                        hidden
                        onChange={법인인감증명서}
                      />
                      {파일선택텍스트("corporateSealCertificate")}
                    </Button>
                    {errors.corporateSealCertificate && (
                      <Typography variant="caption" color="error">
                        {errors.corporateSealCertificate.message}
                      </Typography>
                    )}
                  </Stack>
                </Grid>

                <Grid item xs={12} sm={12}>
                  <Stack direction={"row"} gap={1} alignItems={"center"}>
                    <InputLabel>사용인감계</InputLabel>
                    <Typography sx={{ fontSize: "12px", color: "gray" }}>
                      (원본 1부)
                    </Typography>
                    <Typography sx={{ fontSize: "12px", color: "error.main" }}>
                      * 서류는 제출기한 기준 최근 3개월 이내 발급 받은 것
                    </Typography>
                  </Stack>
                  <Stack direction={"row"} gap={2} alignItems={"center"}>
                    <TextField
                      size="small"
                      sx={{ width: 350 }}
                      disabled
                      {...register("사용인감계")}
                    />
                    <Button
                      component="label"
                      sx={{
                        mr: 2,
                        background: "#eee",
                        color: "black",
                        p: 1
                      }}
                    >
                      <input
                        type="file"
                        accept="image/*, .pdf"
                        hidden
                        onChange={사용인감계}
                      />
                      {파일선택텍스트("사용인감계")}
                    </Button>
                    {errors.사용인감계 && (
                      <Typography variant="caption" color="error">
                        {errors.사용인감계.message}
                      </Typography>
                    )}
                  </Stack>
                </Grid>

                <Grid item xs={12} sm={12}>
                  <Stack direction={"row"} alignItems={"center"} gap={1}>
                    <InputLabel>등기사항전부증명서</InputLabel>
                    <Typography sx={{ fontSize: "12px", color: "gray" }}>
                      (원본 1부)
                    </Typography>
                    <Typography sx={{ fontSize: "12px", color: "error.main" }}>
                      * 서류는 제출기한 기준 최근 3개월 이내 발급 받은 것
                    </Typography>
                  </Stack>
                  <Stack direction={"row"} alignItems={"center"}>
                    <TextField
                      size="small"
                      sx={{ width: 350 }}
                      disabled
                      error={!!errors.corporateRegistration}
                      {...register("corporateRegistration", {
                        required: "등기사항전부증명서는 필수 파일입니다."
                      })}
                    />
                    <Button
                      component="label"
                      sx={{
                        mx: 2,
                        background: "#eee",
                        color: "black",
                        p: 1
                      }}
                    >
                      <input
                        type="file"
                        accept="image/*, .pdf"
                        hidden
                        onChange={등기사항전부증명서}
                      />
                      {파일선택텍스트("corporateRegistration")}
                    </Button>
                    {errors.corporateRegistration && (
                      <Typography variant="caption" color="error">
                        {errors.corporateRegistration.message}
                      </Typography>
                    )}
                  </Stack>
                </Grid>

                <Grid item xs={12} sm={12}>
                  <Stack direction={"row"} alignItems={"center"} gap={1}>
                    <InputLabel>실제소유자확인서</InputLabel>
                    <Typography sx={{ fontSize: "12px", color: "gray" }}>
                      (주주명부, 정관, 사원명부 중 택 1)
                    </Typography>
                  </Stack>
                  <TextField
                    size="small"
                    sx={{ width: 350 }}
                    disabled
                    error={!!errors.actualOwnerConfirmation}
                    {...register("actualOwnerConfirmation")}
                  />
                  <Button
                    component="label"
                    sx={{
                      mx: 2,
                      background: "#eee",
                      color: "black",
                      p: 1
                    }}
                  >
                    <input
                      type="file"
                      accept="image/*, .pdf"
                      hidden
                      onChange={실제소유자확인서}
                    />
                    {파일선택텍스트("actualOwnerConfirmation")}
                  </Button>
                  {errors.actualOwnerConfirmation && (
                    <Typography variant="caption" color="error">
                      {errors.actualOwnerConfirmation.message}
                    </Typography>
                  )}
                </Grid>

                <Grid item xs={12} sm={12}>
                  <Stack direction={"row"} gap={1} alignItems={"center"}>
                    <InputLabel>대표자 신분증 (앞면)</InputLabel>
                    <Typography sx={{ fontSize: "12px", color: "gray" }}>
                      (앞면 사본 1부)
                    </Typography>
                  </Stack>
                  <TextField
                    size="small"
                    disabled
                    sx={{ width: 350 }}
                    error={!!errors.idCardFront}
                    {...register("idCardFront", {
                      required: "대표자 신분증은 필수 파일 입니다."
                    })}
                  />
                  <Button
                    component="label"
                    sx={{
                      background: "#eee",
                      color: "black",
                      p: 1,
                      mx: 2
                    }}
                  >
                    <input
                      type="file"
                      accept="image/*, .pdf"
                      hidden
                      onChange={신분증앞}
                    />
                    {파일선택텍스트("idCardFront")}
                  </Button>
                  {errors.idCardFront && (
                    <Typography variant="caption" color="error">
                      {errors.idCardFront.message}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Stack direction={"row"} gap={1} alignItems={"center"}>
                    <InputLabel>대표자 신분증 (뒷면)</InputLabel>
                    <Typography sx={{ fontSize: "12px", color: "gray" }}>
                      (뒷면 사본 1부)
                    </Typography>
                  </Stack>
                  <TextField
                    size="small"
                    disabled
                    sx={{ width: 350 }}
                    error={!!errors.idCardBack}
                    {...register("idCardBack", {
                      required: "대표자 신분증은 필수 파일 입니다."
                    })}
                  />
                  <Button
                    component="label"
                    sx={{
                      background: "#eee",
                      color: "black",
                      p: 1,
                      mx: 2
                    }}
                  >
                    <input
                      type="file"
                      accept="image/*, .pdf"
                      hidden
                      onChange={신분증뒤}
                    />
                    {파일선택텍스트("idCardBack")}
                  </Button>
                  {errors.idCardBack && (
                    <Typography variant="caption" color="error">
                      {errors.idCardBack.message}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} sm={12}>
                  <InputLabel>영중소 등급확인서</InputLabel>
                  <TextField
                    size="small"
                    sx={{ width: 350 }}
                    disabled
                    error={!!errors.gradeConfirmation}
                    {...register("gradeConfirmation")}
                  />
                  <Button
                    component="label"
                    sx={{
                      mx: 2,
                      background: "#eee",
                      color: "black",
                      p: 1
                    }}
                  >
                    <input
                      type="file"
                      accept="image/*, .pdf"
                      hidden
                      onChange={등급확인서}
                    />
                    {파일선택텍스트("gradeConfirmation")}
                  </Button>
                  {errors.gradeConfirmation && (
                    <Typography variant="caption" color="error">
                      {errors.gradeConfirmation.message}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </>
          )}
          {/* 가맹 등록 버튼 ---------------------------------------------------------------------------------------- */}
          <Button
            type="submit"
            variant="contained"
            fullWidth
            sx={{ mt: 3, mb: 1, color: "white" }}
            disabled={buttonDisable}
          >
            가맹 등록
          </Button>
        </form>
      </Paper>
      <Backdrop open={isLoading} sx={{ background: " rgba(0, 0, 0, 0.2)" }}>
        <CircularProgress
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            ml: 25
          }}
        />
      </Backdrop>
    </>
  );
};

export default MerchantInformations;
