import CloseIcon from "@mui/icons-material/Close";
import NavigateNextRoundedIcon from "@mui/icons-material/NavigateNextRounded";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useSetRecoilState } from "recoil";
import {
  getMerchentModify,
  postMerchantApproval
} from "../../api/ContractManagement";
import isModalOpen from "../../recoil/isModalOpen/atom";

interface Props {
  isOpen: boolean;
  modifyId: any;
  onClose: () => void;
  status: string;
}

const ContractManagementModal = ({
  isOpen,
  modifyId,
  onClose,
  status
}: Props) => {
  // ===========================================================================
  // 리코일 스테이트
  // ===========================================================================
  const setIsModalOpen = useSetRecoilState(isModalOpen);

  const [openPrompt, setOpenPrompt] = useState(null);

  // ===============================================================================================
  // 리액트 쿼리
  // ===============================================================================================
  // 가맹점 정보 수정 요청 상세 조회
  const { data, isLoading } = useQuery(
    [`/merchants/modify/${modifyId}`, modifyId],
    () => getMerchentModify(modifyId),
    {
      enabled: isOpen
    }
  );

  // 정보 수정 요청 수락 및 거절
  const { mutate } = useMutation(postMerchantApproval, {
    onSuccess: () => {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "success",
        message: "진행 상태가 변경 되었습니다."
      });
    },
    onError: (error: any) => {
      setIsModalOpen({
        value: true,
        position: "top",
        alertSeverity: "error",
        message: error?.response?.data?.message
      });
    }
  });

  // ===========================================================================
  // 리액트 훅 폼
  // ===========================================================================
  const {
    control,
    register,
    getValues,
    setValue,
    formState: { isValid, errors }
  } = useForm({ defaultValues: { note: "" } });

  // 수락 & 거절 모달창
  const handleOpenPrompt = (type: any) => {
    setOpenPrompt(type);
    setValue("note", "");
  };
  const handleClosePrompt = () => {
    setOpenPrompt(null);
    setValue("note", "");
  };

  // 요청 수락 업데이트
  const onClickApprove = () => {
    const merchantModifyApprove = {
      modifyId: modifyId,
      note: getValues("note"),
      status: "승인"
    };
    mutate(merchantModifyApprove);
    handleClosePrompt();
  };

  // 요청 거절 업데이트
  const onClickReject = () => {
    const merchantModifyReject = {
      modifyId: modifyId,
      note: getValues("note"),
      status: "거절"
    };
    mutate(merchantModifyReject);
    handleClosePrompt();
  };

  return (
    <>
      {!isLoading && data?.content ? (
        <Dialog open={isOpen} fullWidth maxWidth={"sm"}>
          <DialogTitle>
            정보 수정 요청
            <IconButton
              sx={{
                position: "absolute",
                right: 8,
                top: 12
              }}
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <DialogContent sx={{ mb: 1 }}>
            <Box>
              <Stack direction={"row"} spacing={4} justifyContent={"center"}>
                <Stack spacing={3}>
                  <Chip label="수정 전" />
                  <Stack spacing={3}>
                    <TextField
                      label="가맹점명"
                      size="small"
                      value={data.content.beforeMallName}
                      InputProps={{
                        readOnly: true
                      }}
                    />
                    {data?.content.beforeMallNameEn ? (
                      <TextField
                        label="가맹점 영문명"
                        size="small"
                        value={data.content.beforeMallNameEn}
                        InputProps={{
                          readOnly: true
                        }}
                      />
                    ) : null}
                    <TextField
                      label="가맹점 번호"
                      size="small"
                      value={data.content.beforePhone}
                      InputProps={{
                        readOnly: true
                      }}
                    />
                    <TextField
                      label="가맹점 팩스 번호"
                      size="small"
                      value={data.content.beforeFax}
                      InputProps={{
                        readOnly: true
                      }}
                    />
                    <TextField
                      label="가맹점 URL"
                      size="small"
                      value={data.content.beforeMallUrl}
                      InputProps={{
                        readOnly: true
                      }}
                    />
                    <Button
                      variant="outlined"
                      fullWidth
                      onClick={() => handleOpenPrompt("수정요청거절")}
                      disabled={status === "승인" || status === "거절"}
                    >
                      거절
                    </Button>
                  </Stack>
                </Stack>
                <Stack alignItems={"center"} justifyContent={"center"}>
                  <NavigateNextRoundedIcon fontSize="large" color="primary" />
                </Stack>
                <Stack spacing={3}>
                  <Chip label="수정 후" />
                  <Stack spacing={3}>
                    <TextField
                      label="가맹점명"
                      size="small"
                      value={
                        data.content.afterMallName === ""
                          ? data.content.beforeMallName
                          : data.content.afterMallName
                      }
                      InputProps={{
                        readOnly: true
                      }}
                    />
                    {data?.content.beforeMallNameEn ? (
                      <TextField
                        label="가맹점 영문명"
                        size="small"
                        value={
                          data.content.afterMallNameEn === ""
                            ? data.content.beforeMallNameEn
                            : data.content.afterMallNameEn
                        }
                        InputProps={{
                          readOnly: true
                        }}
                      />
                    ) : null}
                    <TextField
                      label="가맹점 번호"
                      size="small"
                      value={
                        data.content.afterPhone === ""
                          ? data.content.beforePhone
                          : data.content.afterPhone
                      }
                      InputProps={{
                        readOnly: true
                      }}
                    />
                    <TextField
                      label="가맹점 팩스 번호"
                      size="small"
                      value={
                        data.content.afterFax === ""
                          ? data.content.beforeFax
                          : data.content.afterFax
                      }
                      InputProps={{
                        readOnly: true
                      }}
                    />
                    <TextField
                      label="가맹점 URL"
                      size="small"
                      value={
                        data.content.afterMallUrl === ""
                          ? data.content.beforeMallUrl
                          : data.content.afterMallUrl
                      }
                      InputProps={{
                        readOnly: true
                      }}
                    />
                    <Button
                      variant="contained"
                      fullWidth
                      sx={{ color: "white" }}
                      onClick={() => handleOpenPrompt("수정요청수락")}
                      disabled={status === "승인" || status === "거절"}
                    >
                      수락
                    </Button>
                  </Stack>
                </Stack>
              </Stack>
            </Box>
          </DialogContent>

          {/* 정보 수정 요청 - 수락 */}
          <Dialog open={openPrompt === "수정요청수락"} fullWidth maxWidth="xs">
            <DialogTitle>가맹점 정보 수정 요청</DialogTitle>
            {/* dialogtitle */}
            <IconButton
              onClick={handleClosePrompt}
              sx={{
                position: "absolute",
                right: 8,
                top: 12
              }}
            >
              <CloseIcon />
            </IconButton>

            <DialogContent>
              <DialogContentText sx={{ mb: 2 }}>
                사유를 반드시 입력해주세요.
              </DialogContentText>

              <Controller
                name="note"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "사유를 반드시 입력해주세요."
                  },
                  maxLength: {
                    value: 50,
                    message: "50자 이하로 입력해 주세요."
                  }
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="사유"
                    required
                    fullWidth
                    multiline
                    maxRows={2}
                    placeholder="사유를 반드시 입력해주세요."
                  />
                )}
              />
              <Button
                variant="contained"
                sx={{ color: "white", mt: 3 }}
                fullWidth
                onClick={onClickApprove}
                disabled={!isValid}
              >
                정보 수정 요청 수락
              </Button>
            </DialogContent>
          </Dialog>

          {/* 정보 수정 요청 - 거절 */}
          <Dialog open={openPrompt === "수정요청거절"} fullWidth maxWidth="xs">
            <DialogTitle>가맹점 정보 수정 요청</DialogTitle>

            <IconButton
              onClick={handleClosePrompt}
              sx={{
                position: "absolute",
                right: 8,
                top: 12
              }}
            >
              <CloseIcon />
            </IconButton>

            <DialogContent>
              <DialogContentText sx={{ mb: 2 }}>
                사유를 반드시 입력해주세요.
              </DialogContentText>
              <TextField
                id="note"
                label="사유"
                {...register("note", {
                  required: "사유를 반드시 입력해주세요.",
                  maxLength: {
                    value: 50,
                    message: "50자 이하로 입력해 주세요."
                  }
                })}
                required
                fullWidth
                multiline
                maxRows={2}
                placeholder="사유를 반드시 입력해주세요."
              />
              {errors.note && <span>{errors.note.message}</span>}
              <Button
                variant="outlined"
                fullWidth
                onClick={onClickReject}
                sx={{ mt: 3 }}
                disabled={!isValid}
              >
                정보 수정 요청 거절
              </Button>
            </DialogContent>
          </Dialog>
        </Dialog>
      ) : null}
    </>
  );
};
export default ContractManagementModal;
